import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    Container,
    Image,
    Card,
    Row,
    Col,
    Button
} from 'react-bootstrap';

import './../Components.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'

// import { getAllGiftCards } from './../../graphql/mutations';
import awsconfig from './../../aws-exports';
import { API, graphqlOperation } from "aws-amplify";

function GiftCardServico() {

    const dispatch = useDispatch();
    const giftCard = useSelector(state => state.giftCard)

    const getCards = async () => {

        // const entry = {
        //     loja: 'all'
        // }
        // const response = await API.graphql(graphqlOperation(getAllGiftCards, entry))

        // if (JSON.parse(response.data.getAllGiftCards).message !== "Success") {
        //     alert(JSON.parse(response.data.getAllGiftCards).message);
        // }
        // else {
        //     dispatch({
        //         type: "GetAllGiftCards",
        //         payload: JSON.parse(response.data.getAllGiftCards).item
        //     })
        // }


    }


    useEffect(() => {
        getCards();
    }, [])

    return (

        <Container id="comofunciona" fluid style={{ margin: 0, padding: 20, backgroundColor: 'white', marginBottom: 10 }}>
            <Row>
                <Col xs={12} lg={12} md={12} className="titleSessionProduct" style={{ margin: 'auto' }} >
                    <h5>GIFT CARDS - Para você ou presentar alguém ou usar com seu Personal Shopper</h5>
                </Col>
            </Row>
            <Row>
                {giftCard.giftCards.map((item, index) =>
                    <Col key={index} xs={12} lg={4} md={6} style={{ margin: 'auto', color: '#024325', marginBottom: 20 }} >
                        <Card style={{ width: '18rem', height: 230, borderRadius: 20, margin: 'auto' }}>
                            <Card.Body>
                                <Card.Img variant="top" src={`https://terrellbanxcontentdata.s3.amazonaws.com/products/${item.PK}.gif`} style={{ height: 143, borderRadius: 10 }} />
                            </Card.Body>
                            <Button variant="success" type="submit" >
                                Comprar
                            </Button>
                        </Card>
                    </Col>

                )}


            </Row>

        </Container>
    )

}

export default GiftCardServico;
