import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import {
    Container,
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import Menu from './../components/Menu'
import './Components.css'
import WhatsAppBotao from './../assets/whatsapp-botao-1024x360.png'


function Header() {

    const profile = useSelector(state => state.profile)

    return (
        <Container fluid style={{ paddingTop: 20, overflowX: 'hidden' }}>
            <Row>
                <Col xs={4} md={3} className="logo" >
                    <a className="logo" href="/"> LE<font style={{color: '#009246'}}>G</font><font style={{color: '#ce2b37'}}>G</font>E</a>
                </Col>
                <Col xs={2} md={6} style={{ paddingTop: 10 }}>
                    <Menu />
                </Col>
                <Col xs={5} md={1}>
                    <a href="https://api.whatsapp.com/send?phone=4078073529" target='_blank'>
                        <Image src={WhatsAppBotao} style={{ width: 200 }}></Image>
                    </a>
                </Col>
            </Row>

        </Container>

    )
}

export default Header;
