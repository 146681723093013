import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    Container,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
    Button,
    Spinner,
} from 'react-bootstrap';
import Menu from './../components/Menu'
import LeftMenuUserLogged from './../components/LeftMenuUserLogged'
import RodapeComponent from './../components/Rodape'
import HeaderComponent from './../components/Header'
import NossosProdutosComponent from './../components/NossosProdutos'
import CartaoServicoComponent from './../components/UserLogged/CartaoServico'
import GiftCardServicoComponent from './../components/UserLogged/GiftCardsServico'
import PaymentRequestsServico from '../components/UserLogged/PaymentRequestsServico';
import './Pages.css'

// import { signIn } from './../graphql/mutations';
import awsconfig from './../aws-exports';
import { API, graphqlOperation } from "aws-amplify";
import { Redirect } from 'react-router-dom';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';


API.configure(awsconfig);

function Dashboard({ navigation }) {

    const profile = useSelector(state => state.profile);

    return (
        <>
            {!profile.content ?
                <Redirect to="/" />
                :
                <Container id="home" fluid style={{ margin: 0, padding: 0, overflowX: 'hidden' }}>
                    <HeaderComponent />
                    <Container fluid style={{ margin: 0, padding: 10, backgroundColor: '#2f4a74' }}>
                    </Container>
                    <Container fluid style={{ marginBottom: 30 }}>
                        <Row>
                            <Col xs={12} md={3} style={{ marginBottom: 20, padding: 20, widht: '100%', backgroundColor: '#f6f6f6' }}>
                                <LeftMenuUserLogged activepage={"Dashboard"}/>
                            </Col>
                            <Col xs={12} md={9} style={{ margin: 'auto' }}>
                                <PaymentRequestsServico />
                                <CartaoServicoComponent />
                                <GiftCardServicoComponent />
                            </Col>
                        </Row>


                    </Container >

                    <NossosProdutosComponent />
                    <RodapeComponent />

                </Container>
            }

        </>
    )
}

export default Dashboard;
