import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    Container,
    Image,
    Card,
    Row,
    Col,
    Button
} from 'react-bootstrap';
import {
    useParams
} from "react-router-dom";

import './../Components.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'

import { listPaymentRequests } from '../../graphql/queries';
import awsconfig from '../../aws-exports';
import { API, graphqlOperation } from "aws-amplify";

function PaymentRequestsServico() {

    const dispatch = useDispatch();
    const { paymentrequestid } = useParams();

    const profile = useSelector(state => state.profile)
    const paymentRequest = useSelector(state => state.paymentRequest)

    const getPaymentsRequestsFromUser = async () => {

        // Check if email already used
        const listFilterPaymentRequests = {
            emailcliente: {
                eq: profile.content.email
            },
            status: {
                eq: 'Pendente'
            }
        }


        const paymentRequestsData = await API.graphql(graphqlOperation(listPaymentRequests, { filter: listFilterPaymentRequests }))

        if (paymentRequestsData.data.listPaymentRequests.items.length > 0) {

            dispatch({
                type: "GetPaymentRequestsPending",
                payload: paymentRequestsData.data.listPaymentRequests.items
            })

        }

    }


    useEffect(() => {
        getPaymentsRequestsFromUser();
    }, [])

    return (
        <>
            {paymentRequest.paymentRequests.length > 0 ?
                <Container id="comofunciona" fluid style={{ margin: 0, padding: 20, backgroundColor: 'white', marginBottom: 10 }}>
                    <Row>
                        <Col xs={12} lg={12} md={12} className="titleSessionProduct" style={{ margin: 'auto' }} >
                            <h5>Você tem uma solicitação de pagamento pendente!</h5>
                        </Col>
                    </Row>
                    <Row>
                        {paymentRequest.paymentRequests.map((item, index) =>
                            <Col key={index} xs={12} lg={4} md={6} style={{ color: '#024325', marginBottom: 20 }} >
                                <Card style={{ width: '18rem', height: 300, borderRadius: 20, margin: 'auto' }}>
                                    <Card.Body>
                                        <Card.Img variant="top" src={` https://terrellbanx-imagesb2b.s3.amazonaws.com/${item.personalshopperb2b.id}.logo`} style={{ height: 143, borderRadius: 10, objectFit: "contain" }} />
                                        <Card.Text>
                                            <b>Enviado por:</b> {item.personalshopperb2b.nomeloja} <br />
                                            <b>Descrição:</b> {item.descricao} <br />
                                            <b>Valor em {item.moedarequerida}:</b> {parseFloat(item.valorrequerido).toFixed(2)}
                                        </Card.Text>
                                    </Card.Body>
                                    <Button variant="success" onClick={() => {
                                        window.location = `/checkoutpaymentrequest/${item.id}`
                                    }} >
                                        Pagar
                                    </Button>
                                </Card>
                            </Col>

                        )}


                    </Row>

                </Container>
                :
                <></>
            }
        </>
    )

}

export default PaymentRequestsServico;
