import React from 'react';
import {
    Container,
    Image,
    Card,
    Row,
    Col
} from 'react-bootstrap';

import './Components.css'

function NossosServicos() {

    return (
        <Container id="nossoServicos" fluid style={{ margin: 0, padding: 20, backgroundColor: '#fff', marginBottom: 10 }}>
            <Row>
                <Col xs={12} className="titleSession" style={{ margin: 'auto' }} >
                    Nossos Serviços
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={3} md={6} style={{ marginBottom: 10 }}>
                    <Card style={{ width: '18rem', height: 450, border: 0 }}>
                        <Card.Img variant="top" src="https://images.pexels.com/photos/327540/pexels-photo-327540.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" style={{ height: 192, objectFit: 'cover' }} />
                        <Card.Body>
                            <Card.Title>Avaliação de Elegibilidade de Cidadania</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Realizamos avaliações detalhadas com base em sua ancestralidade.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={3} md={6} style={{ marginBottom: 10 }}>
                    <Card style={{ width: '18rem', height: 450, border: 0 }}>
                        <Card.Img variant="top" src="https://images.pexels.com/photos/7235894/pexels-photo-7235894.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" style={{ height: 192, objectFit: 'cover' }} />
                        <Card.Body>
                            <Card.Title>Obtenção de Registros Vitais Italianos</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Se necessário, cuidamos da obtenção de documentos governamentais da Itália, como certidões de nascimento, casamento, óbito.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={3} md={6} style={{ marginBottom: 10 }}>
                    <Card style={{ width: '18rem', height: 450, border: 0 }}>
                        <Card.Img variant="top" src="https://images.pexels.com/photos/17323579/pexels-photo-17323579/free-photo-of-adulto-arte-serie-de-livros-idosos.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" style={{ height: 192, objectFit: 'cover' }} />
                        <Card.Body>
                            <Card.Title>Pesquisa Genealógica</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Nossos genealogistas qualificados estão prontos para rastrear suas raízes familiares e reunir a documentação necessária.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={3} md={6} style={{ marginBottom: 10 }}>
                    <Card style={{ width: '18rem', height: 450, border: 0 }}>
                        <Card.Img variant="top" src="https://images.pexels.com/photos/8111821/pexels-photo-8111821.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" style={{ height: 192, objectFit: 'cover' }} />
                        <Card.Body>
                            <Card.Title>Consulta Jurídica</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Oferecemos assessoria jurídica especializada, guiando você pelos requisitos e procedimentos para a cidadania italiana.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Col xs={12} lg={3} md={5} style={{ marginBottom: 10 }}>
                    <Card style={{ width: '18rem', height: 450, border: 0 }}>
                        <Card.Img variant="top" src="https://images.pexels.com/photos/2928232/pexels-photo-2928232.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" style={{ height: 192, objectFit: 'cover' }} />
                        <Card.Body>
                            <Card.Title>Assistência ao Pedido</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Auxiliamos no preenchimento e envio de pedidos, garantindo que todos os documentos estejam em conformidade com os regulamentos italianos.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={3} md={5} style={{ marginBottom: 10 }}>
                    <Card style={{ width: '18rem', height: 450, border: 0 }}>
                        <Card.Img variant="top" src="https://images.pexels.com/photos/4427430/pexels-photo-4427430.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" style={{ height: 192, objectFit: 'cover' }} />
                        <Card.Body>
                            <Card.Title>Representação Jurídica</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Representamos nossos clientes perante consulados e autoridades italianas, defendendo seus pedidos e resolvendo quaisquer questões durante o processo.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={3} md={5} style={{ marginBottom: 10 }}>
                    <Card style={{ width: '18rem', height: 450, border: 0 }}>
                        <Card.Img variant="top" src="https://estaticos.globoradio.globo.com/fotos/2022/08/ccdf88eb-0bf6-49a0-99e2-2a593dac6294.png.640x360_q75_box-0%2C33%2C400%2C258_crop_detail.jpg" style={{ height: 192, objectFit: 'cover' }} />
                        <Card.Body>
                            <Card.Title>Orientação para Dupla Cidadania</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Oferecemos orientação sobre as implicações da dupla cidadania, ajudando você a navegar pelos aspectos legais.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={3} md={5} style={{ marginBottom: 10 }}>
                    <Card style={{ width: '18rem', height: 450, border: 0 }}>
                        <Card.Img variant="top" src="https://images.pexels.com/photos/267669/pexels-photo-267669.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" style={{ height: 192, objectFit: 'cover' }} />
                        <Card.Body>
                            <Card.Title>Traduções e Apostilas</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Nossa equipe de tradutores certificados pode traduzir documentos brasileiros, e podemos autenticar documentos dos EUA para sua solicitação de dupla cidadania italiana.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row style={{ padding: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Col xs={12} lg={3} md={4} style={{ color: '#024325', marginBottom: 20 }} >
                    <Container style={{ color: '#2f4a74' }}>
                        <Image src="https://images.pexels.com/photos/3201694/pexels-photo-3201694.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" fluid style={{ height: 300, width: '100%', objectFit: 'cover' }} />
                    </Container>
                </Col>
                <Col xs={12} lg={8} md={8} style={{ marginLeft: 10, marginBottom: 20, alignSelf: 'center' }}>
                    <Card style={{ width: 'auto', height: 'auto', border: 0 }}>
                        <Card.Body>
                            <Card.Title >Por que escolher-nos para sua jornada de dupla cidadania italiana?</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Somos especialistas que compreendem que cada jornada é única. Oferecemos uma consulta gratuita de 40 minutos, nossos honorários são transparentes e competitivos, e estamos prontos para guiá-lo, seja através de uma solicitação judicial, orientação na Itália ou assistência legal através de nosso advogado italiano.
                                Conhecemos as dores e anseios, pois já passamos por isso. Evite erros, cumpra os prazos e comece sua vida na Itália conosco. Entre em contato hoje via nosso site ou WhatsApp para explorar mais sobre seus ancestrais italianos e obter a dupla cidadania italiana. Estamos ansiosos para fazer parte desta jornada com você!
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    )

}

export default NossosServicos;
