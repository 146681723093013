import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './pages/Home'
import 'bootstrap/dist/css/bootstrap.min.css';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import SignUpB2B from './pages/SignUpB2B';
import Dashboard from './pages/Dashboard';
import CheckoutPaymentRequest from './pages/CheckoutPaymentRequest';
import PaymentHistory from './pages/PaymentHistory';
import AccountDocuments from './pages/AccountDocuments';
import Thunk from './admin/BackofficeThunk';
import CreatePaymentRequest from './pages/CreatePaymentRequest';


function App() {

  return (
    <Router>
      <Switch>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/signupb2b">
          <SignUpB2B />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/createpaymentrequest">
          <CreatePaymentRequest />
        </Route>
        <Route path="/checkoutpaymentrequest/:paymentrequestid">
          <CheckoutPaymentRequest />
        </Route>
        <Route path="/paymenthistory">
          <PaymentHistory />
        </Route>
        <Route path="/accountdocuments/:requestid">
          <AccountDocuments />
        </Route>
        <Route path="/thunk">
          <Thunk />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router >
  )

}

export default App;
