import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    Container,
    Image,
    Card,
    Row,
    Col,
    Button,
    Form,
    Spinner,
    Modal
} from 'react-bootstrap';

import {
    useParams
} from "react-router-dom";

import './../Components.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

// import { getPaymentRequest, setPaymentTransaction } from '../../graphql/mutations';
import { getPaymentRequest } from '../../graphql/queries';
import awsconfig from '../../aws-exports';
import { API, graphqlOperation } from "aws-amplify";
import VisaMaster from './../../assets/visamaster.png'

function PaymentRequestCheckoutServico() {

    const dispatch = useDispatch();
    const { paymentrequestid } = useParams();

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const [numeroCartaoError, setNumeroCartaoError] = useState("");
    const [mesCartaoError, setMesCartaoError] = useState("");
    const [anoCartaoError, setAnoCartaoError] = useState("");
    const [cvcCartaoError, setCvcCartaoError] = useState("");

    const [show, setShow] = useState(false);


    const [valorDolar, setValorDolar] = useState(0);
    const [taxaServicoPercentual, setTaxaServicoPercentual] = useState(0.095);
    const [valorTaxaServico, setValorTaxaServico] = useState(0);
    const [valorDolarTotalComServico, setValorDolarTotalComServico] = useState(0);
    const [valorDolarTurismo, setValorDolarTurismo] = useState(0);
    const [valorDolarTBX, setValorDolarTBX] = useState(0);
    const [valorTotalReais, setValorTotalReais] = useState(0);
    const [valorTaxasImpostos, setValorTaxasImpostos] = useState(0);
    const [valorTotalTransacao, setValorTotalTransacao] = useState(0);


    const [valorPagamento, setValorPagamento] = useState(0)

    const [confirmacaoPagamento, setConfirmacaoPagamento] = useState("KDJA123jKD12")

    const txtNumeroCartao = useRef(null);
    const txtMesCartao = useRef(null);
    const txtAnoCartao = useRef(null);
    const txtCVCCartao = useRef(null);
    const selParcelamento = useRef(null);

    const profile = useSelector(state => state.profile)
    const paymentRequest = useSelector(state => state.paymentRequest)

    const handleClose = () => {
        window.location = `/paymenthistory`;
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const getPaymentsRequestsFromUser = async () => {

        const getPaymentRequestData = await API.graphql(graphqlOperation(getPaymentRequest, { id: paymentrequestid }))

        // window.paypal.Buttons().render('#paypalterrellbanx');
        window.paypal.Buttons({
            createOrder: function (data, actions) {
                // This function sets up the details of the transaction, including the amount and line item details.
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            value: `${getPaymentRequestData.data.getPaymentRequest.valorrequerido}`
                        }
                    }]
                });
            }
        }).render('#paypalterrellbanx');

        // const entry = {
        //     email: profile.content.SK.S,
        //     status: 'pending'

        // }
        // const response = await API.graphql(graphqlOperation(getPaymentRequest, entry))

        // if (JSON.parse(response.data.getPaymentRequest).message !== "Success") {
        //     alert(JSON.parse(response.data.getPaymentRequest).message);
        // }
        // else {
        //     dispatch({
        //         type: "GetPaymentRequestsPending",
        //         payload: JSON.parse(response.data.getPaymentRequest).item
        //     })

        //     const paymentObject = JSON.parse(response.data.getPaymentRequest).item[0]

        //     setValorDolar(paymentObject.valorDolar.N);
        //     setValorDolarTotalComServico(parseFloat(paymentObject.valorDolar.N) + parseFloat((taxaServicoPercentual * paymentObject.valorDolar.N)));
        //     setValorTaxaServico(taxaServicoPercentual * paymentObject.valorDolar.N);
        //     setValorDolarTurismo(paymentObject.taxaConversao.N);
        //     setValorDolarTBX(paymentObject.taxaConversao.N * 0.97);
        //     setValorTotalReais((paymentObject.taxaConversao.N * 0.97) * (parseFloat(paymentObject.valorDolar.N) + parseFloat((taxaServicoPercentual * paymentObject.valorDolar.N))));
        //     setValorTotalTransacao(paymentObject.valorDolar.N * paymentObject.cambioEfetivo.N);
        //     setValorTaxasImpostos((paymentObject.valorDolar.N * paymentObject.cambioEfetivo.N) - ((paymentObject.taxaConversao.N * 0.97) * (parseFloat(paymentObject.valorDolar.N) + parseFloat((taxaServicoPercentual * paymentObject.valorDolar.N)))));

        // }

    }

    const handleSubmit = async (event) => {

        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);



        let regCreditCard = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
        let regExpirationDate = /(0[1-9]|10|11|12)\/20[0-9]{2}$/;
        let regCVC = /^[0-9]{3}$/;

        // if (regCreditCard.test(txtNumeroCartao.current.value) === false) {
        //     setNumeroCartaoError("Número de cartão inválido")
        //     txtNumeroCartao.current.setCustomValidity("Invalid field.");
        // }
        // else {

        //     if (!txtNumeroCartao.current.value || !luhn_validate(txtNumeroCartao.current.value)) {
        //         setNumeroCartaoError("Número de cartão inválido")
        //         txtNumeroCartao.current.setCustomValidity("Invalid field.");
        //     }
        //     else
        //         txtNumeroCartao.current.setCustomValidity("");
        // }


        if (regExpirationDate.test(txtMesCartao.current.value) === false) {
            setMesCartaoError("Validade do cartão inválida, informe o mês com dois digitos e o ano com 4 digitos")
            txtMesCartao.current.setCustomValidity("Invalid field.");
        }
        else {
            let dataAtual = new Date();

            if (parseInt(txtMesCartao.current.value.split("/")[1]) < parseInt(dataAtual.getFullYear())) {
                setMesCartaoError("Validade do cartão inválida")
                txtMesCartao.current.setCustomValidity("Invalid field.");
            } else if (parseInt(txtMesCartao.current.value.split("/")[1]) === parseInt(dataAtual.getFullYear())) {

                if (parseInt(txtMesCartao.current.value.split("/")[0]) < parseInt(dataAtual.getMonth()) + 1) {
                    setMesCartaoError("Validade do cartão inválida")
                    txtMesCartao.current.setCustomValidity("Invalid field.");
                }
                else
                    txtMesCartao.current.setCustomValidity("");
            }
        }

        if (regCVC.test(txtCVCCartao.current.value) === false) {
            setCvcCartaoError("CVC inválido!")
            txtCVCCartao.current.setCustomValidity("Invalid field.");
        }
        else
            txtCVCCartao.current.setCustomValidity("");

        if (form.checkValidity() === false) {
            txtNumeroCartao.current.focus();

            setIsLoading(false);

        }
        else {

            let valorpagamentofinal = 0
            let parcelasfinal = ""
            let datapagamento = new Date(Date.now()).toLocaleDateString('pt-BR');


            if (document.getElementById("selParcelamentoRadio1").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao).toFixed(2)
                parcelasfinal = `Á vista: R$ ${parseFloat(valorTotalTransacao).toFixed(2)}`
            } else if (document.getElementById("selParcelamentoRadio2").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao).toFixed(2)
                parcelasfinal = `2x parcelas de: R$ ${parseFloat(valorTotalTransacao / 2).toFixed(2)}`
            } else if (document.getElementById("selParcelamentoRadio3").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao).toFixed(2)
                parcelasfinal = `3x parcelas de: R$ ${parseFloat(valorTotalTransacao / 3).toFixed(2)}`
            } else if (document.getElementById("selParcelamentoRadio4").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao * (1 + (0.0195 * 1))).toFixed(2)
                parcelasfinal = `4x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * 1.0195 * 1 / 4).toFixed(2)}`
            } else if (document.getElementById("selParcelamentoRadio5").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao * (1 + (0.0195 * 2))).toFixed(2)
                parcelasfinal = `5x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 2)) / 5).toFixed(2)}`
            } else if (document.getElementById("selParcelamentoRadio6").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao * (1 + (0.0195 * 3))).toFixed(2)
                parcelasfinal = `6x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 3)) / 6).toFixed(2)}`
            } else if (document.getElementById("selParcelamentoRadio7").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao * (1 + (0.0195 * 4))).toFixed(2)
                parcelasfinal = `7x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 4)) / 7).toFixed(2)}`
            } else if (document.getElementById("selParcelamentoRadio8").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao * (1 + (0.0195 * 5))).toFixed(2)
                parcelasfinal = `8x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 5)) / 8).toFixed(2)}`
            } else if (document.getElementById("selParcelamentoRadio9").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao * (1 + (0.0195 * 6))).toFixed(2)
                parcelasfinal = `9x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 6)) / 9).toFixed(2)}`
            } else if (document.getElementById("selParcelamentoRadio10").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao * (1 + (0.0195 * 7))).toFixed(2)
                parcelasfinal = `10x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 7)) / 10).toFixed(2)}`
            } else if (document.getElementById("selParcelamentoRadio11").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao * (1 + (0.0195 * 8))).toFixed(2)
                parcelasfinal = `11x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 8)) / 11).toFixed(2)}`
            } else if (document.getElementById("selParcelamentoRadio12").checked) {
                valorpagamentofinal = parseFloat(valorTotalTransacao * (1 + (0.0195 * 9))).toFixed(2)
                parcelasfinal = `12x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 9)) / 12).toFixed(2)}`
            }

            const entry = {
                email: profile.content.SK.S,
                cc: txtNumeroCartao.current.value,
                exp: txtMesCartao.current.value,
                cvc: txtCVCCartao.current.value,
                nome: profile.content.nome.S,
                sobrenome: profile.content.sobrenome.S,
                cpf: profile.content.cpf.S,
                datanascimento: profile.content.datanascimento.S,
                endereco: profile.content.endereco.S,
                complemento: profile.content.complemento.S,
                numero: profile.content.numero.S,
                bairro: profile.content.bairro.S,
                cidade: profile.content.cidade.S,
                estado: profile.content.estado.S,
                telefone: profile.content.telefone.S,
                celular: profile.content.celular.S,
                paymentid: paymentrequestid,
                valorpagamento: valorpagamentofinal,
                parcelas: parcelasfinal,
                algoritimo: 'aes-256-ctr',
                datapagamento: datapagamento
            }
            // const response = await API.graphql(graphqlOperation(setPaymentTransaction, entry))

            // if (JSON.parse(response.data.setPaymentTransaction).message !== "Success") {
            //     alert(JSON.parse(response.data.setPaymentTransaction).message);
            // }
            // else {

            //     setConfirmacaoPagamento(JSON.parse(response.data.setPaymentTransaction).Id)
            //     handleShow()
            // }
            setIsLoading(false);
        }

        setValidated(true);

    }

    useEffect(() => {
        getPaymentsRequestsFromUser();
    }, [])


    const onSuccess = (payment) => {
        // Congratulation, it came here means everything's fine!
        console.log("The payment was succeeded!", payment);
        // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    }

    const onCancel = (data) => {
        // User pressed "cancel" or close Paypal's popup!
        console.log('The payment was cancelled!', data);
        // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    }

    const onError = (err) => {
        // The main Paypal's script cannot be loaded or somethings block the loading of that script!
        console.log("Error!", err);
        // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
        // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    }

    let env = 'sandbox'; // you can set here to 'production' for production
    let currency = 'BRL'; // or you can set this value from your props or state
    let total = 60; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
    // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

    const client = {
        sandbox: 'Af2dfnRNM3UoU6M3wsmWZS9N-wWk6SNaUix79A11J9AwwPebacimElc-Ot3dSCoz-FkIwS1H5ewiXLEf',
        production: 'YOUR-PRODUCTION-APP-ID',
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação de pagamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{ alignContent: 'center', margin: 'auto', textAlign: 'center' }}>
                        <Container style={{ marginTop: 40 }}>
                            <FontAwesomeIcon icon={faCheckCircle} size="5x" color={"green"} />
                        </Container>
                        <Container style={{ marginTop: 10, color: "green", fontSize: "30px" }}>
                            Confirmação: {confirmacaoPagamento}
                        </Container>
                        <Container style={{ marginTop: 40, fontSize: "20px" }}>
                            Seu pagamento será processado nas próximas 24 horas. Para acompanhar o status de seu pagamento acesse Minhas Compras no menu de sua conta.
                        </Container>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Container id="comofunciona" fluid style={{ margin: 0, padding: 20, backgroundColor: 'white', marginBottom: 10 }}>
                <Row>
                    <Col xs={12} lg={12} md={12} className="titleSessionProduct" style={{ margin: 'auto' }} >
                    </Col>
                </Row>
                <Row>
                    <Container id="paypalterrellbanx" fluid style={{ margin: 0, padding: 10 }}>
                    </Container>

                    <Col xs={12} lg={6} md={6} style={{ color: '#024325', marginBottom: 20 }} >
                        <h5>Detalhes de pagamento</h5>
                        <Image src={VisaMaster} style={{ width: 100, marginBottom: 10 }}></Image>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="rowForm">
                                <Col style={{ marginBottom: 15 }}>
                                    Número do cartão* <br />
                                    <Form.Control placeholder="Número do cartão"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtNumeroCartao}
                                        onChange={() => { txtNumeroCartao.current.setCustomValidity("") }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {numeroCartaoError}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }}>
                                    Validade do cartão*
                                    <Form.Control placeholder="MM / YYYY"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtMesCartao}
                                        onChange={() => { txtMesCartao.current.setCustomValidity("") }}
                                        style={{ width: 150 }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {mesCartaoError}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col style={{ marginBottom: 15 }}>
                                    CVC*
                                    <Form.Control placeholder="CVC"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtCVCCartao}
                                        onChange={() => { txtCVCCartao.current.setCustomValidity("") }}
                                        style={{ width: 100 }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {cvcCartaoError}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>

                                <Col style={{ marginBottom: 15 }}>
                                    Forma de pagamento* <br />

                                    <Form.Check
                                        type='radio'
                                        label={`Á vista: R$ ${parseFloat(valorTotalTransacao).toFixed(2)}`}
                                        value="1"
                                        name="selParcelamentoRadio"
                                        id="selParcelamentoRadio1"
                                        checked
                                    />
                                    <Form.Check
                                        type='radio'
                                        label={`2x parcelas de: R$ ${parseFloat(valorTotalTransacao / 2).toFixed(2)}`}
                                        value="2"
                                        name="selParcelamentoRadio"
                                        id="selParcelamentoRadio2"

                                    />
                                    <Form.Check
                                        type='radio'
                                        label={`3x parcelas de: R$ ${parseFloat(valorTotalTransacao / 3).toFixed(2)}`}
                                        value="3"
                                        name="selParcelamentoRadio"
                                        id="selParcelamentoRadio3"

                                    />
                                    <Form.Check
                                        type='radio'
                                        value="4"
                                        label={`4x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * 1.0195 * 1 / 4).toFixed(2)}`}
                                        name="selParcelamentoRadio"
                                        id="selParcelamentoRadio4"
                                    />

                                    <Form.Check
                                        type='radio'
                                        label={`5x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 2)) / 5).toFixed(2)}`}
                                        value="5"
                                        name="selParcelamentoRadio"
                                        id="selParcelamentoRadio5"
                                    />

                                    <Form.Check
                                        type='radio'
                                        label={`6x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 3)) / 6).toFixed(2)}`}
                                        value="6"
                                        name="selParcelamentoRadio"
                                        id="selParcelamentoRadio6"

                                    />

                                    <Form.Check
                                        type='radio'
                                        label={`7x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 4)) / 7).toFixed(2)}`}
                                        value="7"
                                        name="selParcelamentoRadio"
                                        id="selParcelamentoRadio7"

                                    />

                                    <Form.Check
                                        type='radio'
                                        label={`8x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 5)) / 8).toFixed(2)}`}
                                        value="8"
                                        name="selParcelamentoRadio"
                                        id="selParcelamentoRadio8"

                                    />

                                    <Form.Check
                                        type='radio'
                                        label={`9x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 6)) / 9).toFixed(2)}`}
                                        name="selParcelamentoRadio"
                                        value="9"
                                        id="selParcelamentoRadio9"


                                    />

                                    <Form.Check
                                        type='radio'
                                        label={`10x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 7)) / 10).toFixed(2)}`}
                                        name="selParcelamentoRadio"
                                        value="10"
                                        id="selParcelamentoRadio10"

                                    />

                                    <Form.Check
                                        type='radio'
                                        label={`11x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 8)) / 11).toFixed(2)}`}
                                        name="selParcelamentoRadio"
                                        value="11"
                                        id="selParcelamentoRadio11"

                                    />

                                    <Form.Check
                                        type='radio'
                                        label={`12x (1.95% juros ao mês) parcelas de: R$ ${parseFloat(valorTotalTransacao * (1 + (0.0195 * 9)) / 12).toFixed(2)}`}
                                        name="selParcelamentoRadio"
                                        value="12"
                                        id="selParcelamentoRadio12"

                                    />

                                </Col>
                            </Row>
                            <Row >
                                <Col style={{ marginBottom: 15 }}>
                                    {
                                        !isLoading ?
                                            <>
                                                <Button variant="primary" type="submit">
                                                    Pagar
                                        </Button>
                                            </>
                                            :
                                            <>
                                                <Button variant="primary" type="submit" disabled={true}>
                                                    <Spinner size="sm" animation="border" style={{ marginRight: 10 }} />Pagar
                                        </Button>
                                            </>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </Col>

                    {/* {paymentRequest.paymentRequests.filter(item => item.PK.S === paymentrequestid).map((item, index) =>
                        <Col key={index} xs={12} lg={6} md={6} style={{ margin: 'top', color: '#024325', marginBottom: 20 }} >
                            <h5>Detalhes da solicitação</h5>
                            <Card style={{ width: '25rem', height: 450, borderRadius: 20 }}>
                                <Card.Body>
                                    <Card.Img variant="top" src={` https://terrellbanxcontentdata.s3.amazonaws.com/StoresLogo/chersoni.jpg`} style={{ height: 143, borderRadius: 10, objectFit: "contain" }} />
                                    <Card.Text>
                                        <b>Enviado por:</b> {item.usuarioEnvio.S} <br />
                                        <b>Descrição:</b> {item.descricao.S} <br />
                                        <b>Valor a ser pago em US$:</b> {parseFloat(valorDolar).toFixed(2)}<br />
                                        <b>Taxa de serviço US$:</b> {parseFloat(valorTaxaServico).toFixed(2)} <br />
                                        <b>Total em US$:</b> {parseFloat(valorDolarTotalComServico).toFixed(2)} <br />
                                        <font style={{ textDecoration: 'line-through' }}><b>Cambio dolar turismo R$:</b> {parseFloat(valorDolarTurismo).toFixed(2)}</font><br />
                                        <b>Cambio exclusivo TerrellBanx R$:</b> {parseFloat(valorDolarTBX).toFixed(2)} <br />
                                        <b>Total em R$:</b> {parseFloat(valorTotalReais).toFixed(2)} <br />
                                        <b>Taxas e impostos R$:</b> {parseFloat(valorTaxasImpostos).toFixed(2)} <br />
                                        <b>Total em até 3x sem juros R$:</b> {parseFloat(valorTotalTransacao).toFixed(2)} <br />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                    )} */}


                </Row>

            </Container>
        </>
    )

}

export default PaymentRequestCheckoutServico;
