/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomerProfile = /* GraphQL */ `
  mutation CreateCustomerProfile(
    $input: CreateCustomerProfileInput!
    $condition: ModelCustomerProfileConditionInput
  ) {
    createCustomerProfile(input: $input, condition: $condition) {
      id
      email
      senha
      nome
      sobrenome
      cpf
      datanascimento
      endereco
      complemento
      numero
      bairro
      cidade
      estado
      telefone
      celular
      cep
      b2b
      routingnumber
      accountnumber
      nomeloja
    }
  }
`;
export const updateCustomerProfile = /* GraphQL */ `
  mutation UpdateCustomerProfile(
    $input: UpdateCustomerProfileInput!
    $condition: ModelCustomerProfileConditionInput
  ) {
    updateCustomerProfile(input: $input, condition: $condition) {
      id
      email
      senha
      nome
      sobrenome
      cpf
      datanascimento
      endereco
      complemento
      numero
      bairro
      cidade
      estado
      telefone
      celular
      cep
      b2b
      routingnumber
      accountnumber
      nomeloja
    }
  }
`;
export const deleteCustomerProfile = /* GraphQL */ `
  mutation DeleteCustomerProfile(
    $input: DeleteCustomerProfileInput!
    $condition: ModelCustomerProfileConditionInput
  ) {
    deleteCustomerProfile(input: $input, condition: $condition) {
      id
      email
      senha
      nome
      sobrenome
      cpf
      datanascimento
      endereco
      complemento
      numero
      bairro
      cidade
      estado
      telefone
      celular
      cep
      b2b
      routingnumber
      accountnumber
      nomeloja
    }
  }
`;
export const createCurrencyRate = /* GraphQL */ `
  mutation CreateCurrencyRate(
    $input: CreateCurrencyRateInput!
    $condition: ModelCurrencyRateConditionInput
  ) {
    createCurrencyRate(input: $input, condition: $condition) {
      id
      datacotacao
      moedaorigemsimbolo
      moedaorigem
      moedadestinosimbolo
      moedadestino
      valor
    }
  }
`;
export const updateCurrencyRate = /* GraphQL */ `
  mutation UpdateCurrencyRate(
    $input: UpdateCurrencyRateInput!
    $condition: ModelCurrencyRateConditionInput
  ) {
    updateCurrencyRate(input: $input, condition: $condition) {
      id
      datacotacao
      moedaorigemsimbolo
      moedaorigem
      moedadestinosimbolo
      moedadestino
      valor
    }
  }
`;
export const deleteCurrencyRate = /* GraphQL */ `
  mutation DeleteCurrencyRate(
    $input: DeleteCurrencyRateInput!
    $condition: ModelCurrencyRateConditionInput
  ) {
    deleteCurrencyRate(input: $input, condition: $condition) {
      id
      datacotacao
      moedaorigemsimbolo
      moedaorigem
      moedadestinosimbolo
      moedadestino
      valor
    }
  }
`;
export const createPaymentRequest = /* GraphQL */ `
  mutation CreatePaymentRequest(
    $input: CreatePaymentRequestInput!
    $condition: ModelPaymentRequestConditionInput
  ) {
    createPaymentRequest(input: $input, condition: $condition) {
      id
      emailcliente
      moedaorigemsimbolo
      moedaorigem
      moedadestinosimbolo
      moedadestino
      valorrequerido
      moedarequerida
      valorcotacao
      descricao
      personalshopperb2b {
        id
        nome
        sobrenome
        nomeloja
      }
      status
      datageracao
    }
  }
`;
export const updatePaymentRequest = /* GraphQL */ `
  mutation UpdatePaymentRequest(
    $input: UpdatePaymentRequestInput!
    $condition: ModelPaymentRequestConditionInput
  ) {
    updatePaymentRequest(input: $input, condition: $condition) {
      id
      emailcliente
      moedaorigemsimbolo
      moedaorigem
      moedadestinosimbolo
      moedadestino
      valorrequerido
      moedarequerida
      valorcotacao
      descricao
      personalshopperb2b {
        id
        nome
        sobrenome
        nomeloja
      }
      status
      datageracao
    }
  }
`;
export const deletePaymentRequest = /* GraphQL */ `
  mutation DeletePaymentRequest(
    $input: DeletePaymentRequestInput!
    $condition: ModelPaymentRequestConditionInput
  ) {
    deletePaymentRequest(input: $input, condition: $condition) {
      id
      emailcliente
      moedaorigemsimbolo
      moedaorigem
      moedadestinosimbolo
      moedadestino
      valorrequerido
      moedarequerida
      valorcotacao
      descricao
      personalshopperb2b {
        id
        nome
        sobrenome
        nomeloja
      }
      status
      datageracao
    }
  }
`;
