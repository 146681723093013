import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    Container,
    Image,
    Card,
    Row,
    Col,
    Button,
    Table
} from 'react-bootstrap';
import {
    useParams
} from "react-router-dom";

import './../Components.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

// import { getUserPaymentHistory } from '../../graphql/mutations';
import awsconfig from '../../aws-exports';
import { API, graphqlOperation } from "aws-amplify";

function UserHistoryPayment() {

    const dispatch = useDispatch();

    const profile = useSelector(state => state.profile)

    const [payments, setPayments] = useState([]);

    const getUserPaymentHistoryLocal = async () => {

        // const entry = {
        //     email: profile.content.SK.S

        // }
        // const response = await API.graphql(graphqlOperation(getUserPaymentHistory, entry))

        // if (JSON.parse(response.data.getUserPaymentHistory).message !== "Success") {
        //     alert(JSON.parse(response.data.getUserPaymentHistory).message);
        // }
        // else {
        //     setPayments(JSON.parse(response.data.getUserPaymentHistory).item)
        // }

    }


    useEffect(() => {
        getUserPaymentHistoryLocal();
    }, [])

    return (
        <>
            {payments.length > 0 ?
                <Container fluid style={{ margin: 0, padding: 20, backgroundColor: 'white', marginBottom: 10 }}>
                    <Row>
                        <Col xs={12} lg={12} md={12} className="titleSessionProduct" style={{}} >
                            <h5>HISTÓRICO DE PAGAMENTOS!</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Table striped bordered hover size="sm" style={{}}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Data</th>
                                    <th>Valor pago</th>
                                    <th>Opção de pagamento</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payments.map((item, index) =>
                                    <tr key={index}>

                                        {item.status.S === "Análise pendente" ?

                                            <td><FontAwesomeIcon icon={faExclamationTriangle} color={"red"} style={{ fontSize: '20px', marginRight: 5 }} /> {item.PK.S}</td>
                                            :
                                            <td>{item.PK.S}</td>
                                        }
                                        <td>{item.Info.S}</td>
                                        <td>R$ {parseFloat(item.valorpagamento.S).toFixed(2)}</td>
                                        <td>{item.parcelas.S}</td>
                                        {item.status.S === "Análise pendente" ?
                                            <td>{item.status.S} - <a href={`/accountdocuments/${item.PK.S}`}>Detalhes</a> </td>
                                            :
                                            <td>{item.status.S}</td>
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </Table>


                    </Row>

                </Container>
                :
                <Container fluid style={{ margin: 0, padding: 20, backgroundColor: 'white', marginBottom: 10 }}>
                    <Row>
                        <Col xs={12} lg={12} md={12} className="titleSessionProduct" style={{}} >
                            <h5>VOCÊ NÃO POSSUI NENHUM PAGAMENTO!</h5>
                        </Col>
                    </Row>
                </Container>

            }
        </>
    )

}

export default UserHistoryPayment;
