import React from 'react';
import {
    Container,
    Image,
    Row,
    Col,
    Card
} from 'react-bootstrap';

import './Components.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGifts } from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { faTruck } from '@fortawesome/free-solid-svg-icons'

function NossoProdutos() {

    return (

        <Container id="servicos" fluid style={{ margin: 0, padding: 20, backgroundColor: '#f6f6f6', marginBottom: 10 }}>
            <Row>
                <Col xs={12} lg={3} md={4} className="titleSession" style={{ margin: 'auto' }} >
                    PARA SEU NEGÓCIO
            </Col>
                <Col xs={11} lg={3} md={4} style={{ margin: 'auto', marginBottom: 10 }}>
                    <Card style={{ width: '18rem', height: 400 }}>
                        <Card.Img variant="top" src="https://images.pexels.com/photos/3184306/pexels-photo-3184306.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" style={{ height: 192 }} />
                        <Card.Body>
                            <Card.Title>Para sua empresa</Card.Title>
                            <Card.Text>
                                Quer vender seus produtos parcelados no Brasil? Saiba como integrar nossa plataforma. Clique aqui e saiba mais.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={11} lg={3} md={4} style={{ margin: 'auto', marginBottom: 10 }}>
                    <Card style={{ width: '18rem', height: 400 }}>
                        <Card.Img variant="top" src="https://images.pexels.com/photos/5956/gift-brown-shopping-market.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" style={{ height: 192 }} />
                        <Card.Body>
                            <Card.Title>Para Personal Shoppers </Card.Title>
                            <Card.Text>
                                Você usa nossa plataforma para parcelar as compras se seus clientes, recebe o montante total e ainda uma comissão. Clique aqui e saiba mais.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={11} lg={3} md={4} style={{ margin: 'auto', marginBottom: 10 }}>
                    <Card style={{ width: '18rem', height: 400 }}>
                        <Card.Img variant="top" src="https://images.pexels.com/photos/3183153/pexels-photo-3183153.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" style={{ height: 192 }} />
                        <Card.Body>
                            <Card.Title>Turbine seu negócios</Card.Title>
                            <Card.Text>
                                Você pode ter usa plataforma de venda com parcelamento com a cara do seu negócio, um app Android e IOS e diversas ferramentas para te ajudar no dia a dia. Clique aqui e saiba mais.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    )

}

export default NossoProdutos;
