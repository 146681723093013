import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    Container,
    ListGroup,
    Col,
    Image,
} from 'react-bootstrap';
import Menu from './../components/Menu'
import './Components.css'


function LeftMenuUserLogged({ navigation, activepage }) {

    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile)
    console.log(profile)
    const logout = () => {
        dispatch({
            type: "SignOut",
            payload: undefined
        })
    }

    return (
        <Container >

            <h5>
                {profile.content.nome} {profile.content.sobrenome}<br />
                Bem vindo!
            </h5>
            {profile.content.b2b ?
                <>
                    <ListGroup as="ul">
                        {activepage === "Dashboard" ?
                            <ListGroup.Item as="li" style={{ cursor: 'pointer' }} active action onClick={() => { window.location = `/dashboard` }}>Principal</ListGroup.Item>
                            :
                            <ListGroup.Item as="li" style={{ cursor: 'pointer' }} action onClick={() => { window.location = `/dashboard` }}>Principal</ListGroup.Item>
                        }
                        {activepage === "CreatePaymentRequest" ?

                            <ListGroup.Item as="li" style={{ cursor: 'pointer' }} active action onClick={() => { window.location = `/createpaymentrequest` }}> Gerar solicitacao de pagamentos</ListGroup.Item>
                            :
                            <ListGroup.Item as="li" style={{ cursor: 'pointer' }} action onClick={() => { window.location = `/createpaymentrequest` }}> Gerar solicitacao de pagamentos</ListGroup.Item>
                        }
                        {activepage === "PaymentGenerate" ?

                            <ListGroup.Item as="li" style={{ cursor: 'pointer' }} active action onClick={() => { window.location = `/paymenthistory` }}> Histórico de clientes</ListGroup.Item>
                            :
                            <ListGroup.Item as="li" style={{ cursor: 'pointer' }} action onClick={() => { window.location = `/paymenthistory` }}> Histórico de clientes</ListGroup.Item>
                        }
                        <ListGroup.Item as="li" disabled>Meu cartão TERRELLBANX</ListGroup.Item>
                        <ListGroup.Item as="li" style={{ cursor: 'pointer' }}>Meu perfil</ListGroup.Item>
                        <ListGroup.Item as="li" style={{ cursor: 'pointer' }} action onClick={() => { logout() }}>Sair</ListGroup.Item>
                    </ListGroup>
                    <Image src={`https://terrellbanx-imagesb2b.s3.amazonaws.com/${profile.content.id}.logo`} fluid style={{ marginTop: 30, height: 150, width: '100%', objectFit: 'contain' }} />
                </>
                :
                <ListGroup as="ul">
                    {activepage === "Dashboard" ?
                        <ListGroup.Item as="li" style={{ cursor: 'pointer' }} active action onClick={() => { window.location = `/dashboard` }}>Principal</ListGroup.Item>
                        :
                        <ListGroup.Item as="li" style={{ cursor: 'pointer' }} action onClick={() => { window.location = `/dashboard` }}>Principal</ListGroup.Item>
                    }
                    {activepage === "PaymentHistory" ?

                        <ListGroup.Item as="li" style={{ cursor: 'pointer' }} active action onClick={() => { window.location = `/paymenthistory` }}> Meus pagamentos</ListGroup.Item>
                        :
                        <ListGroup.Item as="li" style={{ cursor: 'pointer' }} action onClick={() => { window.location = `/paymenthistory` }}> Meus pagamentos</ListGroup.Item>
                    }
                    <ListGroup.Item as="li" disabled>Meu cartão TERRELLBANX</ListGroup.Item>
                    <ListGroup.Item as="li" style={{ cursor: 'pointer' }}>Meu perfil</ListGroup.Item>
                    <ListGroup.Item as="li" style={{ cursor: 'pointer' }} action onClick={() => { logout() }}>Sair</ListGroup.Item>
                </ListGroup>

            }

        </Container>

    )
}

export default LeftMenuUserLogged;
