import React, { useState } from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
    NavDropdown,
    OverlayTrigger,
    Popover,
    Button,
    Tooltip,
    Card
} from 'react-bootstrap';
import Menu from './../components/Menu'
import HomeComponent from './../components/Home'
import NossosServicosComponent from './../components/NossosServicos'
import NossosProdutosComponent from './../components/NossosProdutos'
import ServicosComponent from './../components/Servicos'
import RodapeComponent from './../components/Rodape'
import HeaderComponent from './../components/Header'
import CartaoServico from '../components/UserLogged/CartaoServico';
import NossaExperiencia from '../components/Experiencia';
import SobreNos from '../components/SobreNos';

function Home() {

    const [menu, setMenu] = useState(false)

    return (
        <>
            <Container id="home" fluid style={{ margin: 0, padding: 0, overflowX: 'hidden' }}>
                <Container style={{ maxWidth: 1440, margin: 'auto' }}>
                    <HeaderComponent />
                    <HomeComponent />
                    <NossosServicosComponent id="comofunciona" />
                    <NossaExperiencia />
                    <SobreNos />
                </Container>
                {/* <CartaoServico />
                <ServicosComponent />
                <NossosProdutosComponent /> */}
                <RodapeComponent />
            </Container>

        </>
    )

}


export default Home;
