import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    Container,
    Image,
    Card,
    Row,
    Col,
    Button,
    Form,
    Spinner,
    Modal,
    Table
} from 'react-bootstrap';

import axios from 'axios';

import {
    useParams
} from "react-router-dom";

import './../Components.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

// import { getUserPaymentHistory, setPaymentHistoryStatus } from '../../graphql/mutations';
import awsconfig from '../../aws-exports';
import { API, graphqlOperation } from "aws-amplify";
import VisaMaster from './../../assets/visamaster.png'

function DocumentsActiveAccount() {

    const dispatch = useDispatch();
    const { requestid } = useParams();

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const [paymentPending, setPaymentPending] = useState(null);

    const getUserPaymentHistoryLocal = async () => {

        // const entry = {
        //     email: profile.content.SK.S

        // }
        // const response = await API.graphql(graphqlOperation(getUserPaymentHistory, entry))

        // if (JSON.parse(response.data.getUserPaymentHistory).message !== "Success") {
        //     alert(JSON.parse(response.data.getUserPaymentHistory).message);
        // }
        // else {
        //     console.log(JSON.parse(response.data.getUserPaymentHistory).item);
        //     console.log(requestid);
        //     setPaymentPending(JSON.parse(response.data.getUserPaymentHistory).item.filter(item => item.PK.S === requestid)[0])
        // }

    }

    useEffect(() => {
        getUserPaymentHistoryLocal();
    }, [])

    const [documentoIdentificacaoError, setDocumentoIdentificacaoError] = useState("");
    const [faturaCartaoError, setFaturaCartaoError] = useState("");


    const filDocumentoIdentificacao = useRef(null);
    const filFaturaCartao = useRef(null);
    const txtDocumentoIdentificacao = useRef(null);
    const txtFaturaCartao = useRef(null);


    const profile = useSelector(state => state.profile)


    const handleSubmit = async (event) => {

        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);

        console.log(form.files)
        console.log(filDocumentoIdentificacao.current.files[0])

        if (filDocumentoIdentificacao.current.files.length === 0) {
            setDocumentoIdentificacaoError("Arquivo inválido!")
            txtDocumentoIdentificacao.current.setCustomValidity("Invalid field.");
        }
        else
            txtDocumentoIdentificacao.current.setCustomValidity("");


        if (filFaturaCartao.current.files.length === 0) {
            setFaturaCartaoError("Arquivo inválido!")
            txtFaturaCartao.current.setCustomValidity("Invalid field.");
        }
        else
            txtFaturaCartao.current.setCustomValidity("");


        if (form.checkValidity() === false) {

            setIsLoading(false);

        }
        else {

            let formData = new FormData();
            formData.append('file', filDocumentoIdentificacao.current.files[0]);
            formData.append('key', profile.content.PK.S);
            formData.append('filename', filDocumentoIdentificacao.current.files[0].name);
            await axios.post(`https://terrellbanx.com:4000/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            });

            formData = new FormData();
            formData.append('file', filFaturaCartao.current.files[0]);
            formData.append('key', profile.content.PK.S);
            formData.append('filename', filFaturaCartao.current.files[0].name);
            await axios.post(`https://terrellbanx.com:4000/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            });

            // const entry = {
            //     transactionid: requestid,
            //     email: profile.content.SK.S,
            //     novostatus: 'Processando'
            // }
            // const response = await API.graphql(graphqlOperation(setPaymentHistoryStatus, entry))

            // if (JSON.parse(response.data.setPaymentHistoryStatus).message !== "Success") {
            //     alert(JSON.parse(response.data.setPaymentHistoryStatus).message);
            // }

            setIsLoading(false);

            window.location = `/paymenthistory`;
            
        }

        setIsLoading(false);
        setValidated(true);

    }

    return (
        <>
            <Container id="comofunciona" fluid style={{ margin: 0, padding: 20, backgroundColor: 'white', marginBottom: 10 }}>
                <Row>
                    <Col xs={12} lg={12} md={12} className="titleSessionProduct" style={{ margin: 'auto' }} >
                    </Col>
                </Row>
                <Row>

                    <Col xs={12} lg={12} md={12} style={{ color: '#024325', marginBottom: 20 }} >
                        <h5>Detalhes do pagamento</h5>

                        {paymentPending ?
                            <Container fluid style={{ margin: 0, padding: 20, backgroundColor: 'white', marginBottom: 10 }}>
                                <Row>
                                    <Table striped bordered hover size="sm" style={{}}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Data</th>
                                                <th>Valor pago</th>
                                                <th>Opção de pagamento</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {paymentPending.status.S === "Análise pendente" ?

                                                    <td><FontAwesomeIcon icon={faExclamationTriangle} color={"red"} style={{ fontSize: '20px', marginRight: 5 }} /> {paymentPending.PK.S}</td>
                                                    :
                                                    <td>{paymentPending.PK.S}</td>
                                                }
                                                <td>{paymentPending.Info.S}</td>
                                                <td>R$ {parseFloat(paymentPending.valorpagamento.S).toFixed(2)}</td>
                                                <td>{paymentPending.parcelas.S}</td>
                                                <td>{paymentPending.status.S}</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                </Row>

                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Container fluid style={{ margin: 0, padding: 20, backgroundColor: 'white', marginBottom: 10 }}>
                                        <Row>
                                            <Col xs={12} lg={12} md={12} className="titleSessionProduct" style={{}} >
                                                <h5>Para que seu pagamento prossiga, você precisa enviar cópia legível dos seguintes documentos:</h5>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Row className="rowForm">
                                        <Col style={{ marginBottom: 15 }}>
                                            <Form.File id="formcheck-api-regular">
                                                <Form.File.Label>Documento de identificação válido</Form.File.Label>
                                                <Form.File.Input ref={filDocumentoIdentificacao} required />
                                            </Form.File>
                                            <Form.Control placeholder=""
                                                type="text"
                                                aria-describedby="inputGroupPrepend"
                                                style={{ display: 'none' }}
                                                ref={txtDocumentoIdentificacao}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {documentoIdentificacaoError}
                                            </Form.Control.Feedback>

                                        </Col>
                                    </Row>
                                    <Row className="rowForm">
                                        <Col style={{ marginBottom: 15 }}>
                                            <Form.File id="formcheck-api-regular">
                                                <Form.File.Label>Uma fatura recente do cartão **** {paymentPending.finalcartao.S} </Form.File.Label>
                                                <Form.File.Input ref={filFaturaCartao} required />
                                            </Form.File>
                                            <Form.Control placeholder=""
                                                type="text"
                                                aria-describedby="inputGroupPrepend"
                                                style={{ display: 'none' }}
                                                ref={txtFaturaCartao}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {faturaCartaoError}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col style={{ marginTop: 15, marginBottom: 15 }}>
                                            {
                                                !isLoading ?
                                                    <>
                                                        <Button variant="primary" type="submit">
                                                            Enviar
                                        </Button>
                                                    </>
                                                    :
                                                    <>
                                                        <Button variant="primary" type="submit" disabled={true}>
                                                            <Spinner size="sm" animation="border" style={{ marginRight: 10 }} />Enviar
                                        </Button>
                                                    </>
                                            }
                                        </Col>
                                    </Row>
                                </Form>

                            </Container>
                            :
                            <></>
                        }


                    </Col>

                </Row>

            </Container>
        </>
    )

}

export default DocumentsActiveAccount;
