const INITIAL_STATE = {
  paymentRequests: []
};

const paymentRequestsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GetPaymentRequestsPending":
      return {
        ...state,
        paymentRequests: action.payload
      }
    default:
      return state
  }
};

export default paymentRequestsReducer;