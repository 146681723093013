import React from 'react';
import {
    Container,
    Image,
    Row,
    Col
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGifts } from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { faTruck } from '@fortawesome/free-solid-svg-icons'

import './Components.css'

function Servicos() {

    return (

        <Container id="produtos" fluid style={{ margin: 0, padding: 0, backgroundColor: 'white' }}>
            <Row style={{ padding: 40 }}>
                <Col xs={12} lg={3} md={4} className="productsIcon" style={{ margin: 'auto', color: '#024325', marginBottom: 20 }} >
                    <FontAwesomeIcon icon={faGifts} size={50} color={"#2f4a74"} />
                    <Container className="productsText" style={{ color: '#2f4a74' }}>
                        Gift Cards
                    </Container>
                </Col>
                <Col xs={12} lg={3} md={8} className="productsContent" style={{ margin: 'auto', marginLeft: 10, marginBottom: 20 }}>
                    Você pode quer comprar tudo em uma única loja? Já escolheu seus produtos? Ou quer presentear alguém com um cartão presente? Você escolhe a loja, a valor, parcela com seu cartão no Brasil e recebe o número de seu cartão pronto para uso. Clique aqui e saiba mais.
                </Col>
            </Row>
            <Row style={{ padding: 40 }}>
                <Col xs={12} lg={3} md={4} className="productsIcon" style={{ margin: 'auto', color: '#024325', marginBottom: 20 }} >
                    <FontAwesomeIcon icon={faCreditCard} size={50} color={"#2f4a74"} />
                    <Container className="productsText" style={{ color: '#2f4a74' }}>
                        TerrellBANX Card
                    </Container>
                </Col>
                <Col xs={12} lg={3} md={8} className="productsContent" style={{ margin: 'auto', marginLeft: 10, marginBottom: 20 }}>
                    Quer curtir sua viagem sem preocupação? Ou precisa ir a um evento, mas não que pagar tudo de uma vez? Você pode aquirir o cartão <b>TERRELLBANX CARD</b>, parcelar o valor que você precisa com seu cartão no Brasil e usar o seu cartão aqui nos EUA. Clique aqui e saiba mais.
                </Col>
            </Row>
            <Row style={{ padding: 40 }}>
                <Col xs={12} lg={3} md={4} className="productsIcon" style={{ margin: 'auto', color: '#024325', marginBottom: 20 }} >
                    <FontAwesomeIcon icon={faShoppingBag} size={50} color={"#2f4a74"} />
                    <Container className="productsText" style={{ color: '#2f4a74' }}>
                        Encontre seu Personal Shopper
                    </Container>
                </Col>
                <Col xs={12} lg={3} md={8} className="productsContent" style={{ margin: 'auto', marginLeft: 10, marginBottom: 20 }}>
                    Está no Brasil, quer comprar produtos aqui nos EUA mas precisa de ajuda? Use o seu Personal Shopper para fazer suas compras, e pague parcelado. Não tem um Personal Shopper? Ajudamos você a encontrar um e comprar o que precisa. Clique aqui e saiba mais.
                </Col>
            </Row>
            <Row style={{ padding: 40 }}>
                <Col xs={12} lg={3} md={4} className="productsIcon" style={{ margin: 'auto', color: '#024325', marginBottom: 20 }} >
                    <FontAwesomeIcon icon={faTruck} size={50} color={"#2f4a74"} />
                    <Container className="productsText" style={{ color: '#2f4a74' }}>
                        Enviamos seus produtos
                    </Container>
                </Col>
                <Col xs={12} lg={3} md={8} className="productsContent" style={{ margin: 'auto', marginLeft: 10, marginBottom: 20 }}>
                    Achou seus produtos, quer comprar mas a loja não entrega no Brasil? Podemos ajudar você, com um endereço para entrega aqui nos EUA e redirecionamento direto para o Brasil, você ainda pode parcelar o envio. Quer saber como? Cique aqui e saiba mais.
                </Col>
            </Row></Container>
    )

}

export default Servicos;
