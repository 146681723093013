import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux'

import {
    Container,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
    Button,
    Spinner,
    Alert
} from 'react-bootstrap';
import Menu from '../components/Menu'
import './Pages.css'
import './../components/Components.css'

import RodapeComponent from '../components/Rodape'
import HeaderComponent from '../components/Header'

import { createCustomerProfile } from './../graphql/mutations';
import { listCustomerProfiles } from './../graphql/queries';
import awsconfig from './../aws-exports';
import { API, graphqlOperation } from "aws-amplify";
import crypto from "crypto";

API.configure(awsconfig);

function SignIn() {

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState("");

    const txtCPF = useRef(null);
    const txtDataNascimento = useRef(null);
    const txtEmail = useRef(null);
    const txtSenha = useRef(null);
    const txtNome = useRef(null);
    const txtSobreNome = useRef(null);
    const txtEndereco = useRef(null);
    const txtComplemento = useRef(null);
    const txtNumero = useRef(null);
    const txtBairro = useRef(null);
    const txtCEP = useRef(null);
    const txtCidade = useRef(null);
    const txtTelefone = useRef(null);
    const txtMobile = useRef(null);
    const selEstado = useRef(null);
    const txtConfirmarSenha = useRef(null);

    const dispatch = useDispatch();

    const validaCPF = (value) => {

        var CPF = value; // Recebe o valor digitado no campo

        // Aqui começa a checagem do CPF
        var POSICAO, I, SOMA, DV, DV_INFORMADO;
        var DIGITO = new Array(10);
        DV_INFORMADO = CPF.substr(9, 2); // Retira os dois últimos dígitos do número informado

        // Desemembra o número do CPF na array DIGITO
        for (I = 0; I <= 8; I++) {
            DIGITO[I] = CPF.substr(I, 1);
        }

        // Calcula o valor do 10º dígito da verificação
        POSICAO = 10;
        SOMA = 0;
        for (I = 0; I <= 8; I++) {
            SOMA = SOMA + DIGITO[I] * POSICAO;
            POSICAO = POSICAO - 1;
        }
        DIGITO[9] = SOMA % 11;
        if (DIGITO[9] < 2) {
            DIGITO[9] = 0;
        }
        else {
            DIGITO[9] = 11 - parseInt(DIGITO[9]);
        }

        // Calcula o valor do 11º dígito da verificação
        POSICAO = 11;
        SOMA = 0;
        for (I = 0; I <= 9; I++) {
            SOMA = SOMA + DIGITO[I] * POSICAO;
            POSICAO = POSICAO - 1;
        }
        DIGITO[10] = SOMA % 11;
        if (DIGITO[10] < 2) {
            DIGITO[10] = 0;
        }
        else {
            DIGITO[10] = 11 - DIGITO[10];
        }


        // Verifica se os valores dos dígitos verificadores conferem
        DV = DIGITO[9] * 10 + DIGITO[10];

        if (parseInt(DV) !== parseInt(DV_INFORMADO)) {
            return false;
        }
        else
            return true
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setIsLoading(true);

        // Check if field content is valid
        let regPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let regDataNascimento = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

        if (regEmail.test(String(txtEmail.current.value.trim()).toLowerCase()) === false) {
            setError("Email inválido")
            txtEmail.current.setCustomValidity("Invalid field.");
        }
        else
            txtEmail.current.setCustomValidity("");

        if (regPassword.test(String(txtSenha.current.value)) === false)
            txtSenha.current.setCustomValidity("Invalid field.");
        else
            txtSenha.current.setCustomValidity("");

        if (!validaCPF(txtCPF.current.value))
            txtCPF.current.setCustomValidity("Invalid field.");
        else
            txtCPF.current.setCustomValidity("");

        if (regDataNascimento.test(String(txtDataNascimento.current.value)) === false)
            txtDataNascimento.current.setCustomValidity("Invalid field.");
        else
            txtDataNascimento.current.setCustomValidity("");


        if (selEstado.current.value === "Estado...")
            selEstado.current.setCustomValidity("Invalid field.");
        else
            selEstado.current.setCustomValidity("");

        if (txtSenha.current.value !== txtConfirmarSenha.current.value)
            txtConfirmarSenha.current.setCustomValidity("Invalid field.");
        else
            txtConfirmarSenha.current.setCustomValidity("");

        if (form.checkValidity() === false) {
            txtEmail.current.focus();

            setIsLoading(false);

        }
        else {

            try {


                // Check if email already used
                const listFilterProfile = {
                    email: {
                        eq: txtEmail.current.value.trim().toLowerCase()
                    }
                }


                const profiles = await API.graphql(graphqlOperation(listCustomerProfiles, { filter: listFilterProfile }))
                if (profiles.data.listCustomerProfiles.items.length > 0) {
                    setError("Email inválido")
                    txtEmail.current.setCustomValidity("Invalid field.");
                    setIsLoading(false);
                    setIsError(true);
                    alert('Email já cadastrado!')
                }
                else {

                    let cipher = crypto.createCipher("aes256", txtSenha.current.value);
                    let retval = cipher.update(txtEmail.current.value.trim().toLowerCase(), 'utf8', 'hex');
                    retval += cipher.final('hex');
            
                    const entryProfile = {
                        email: txtEmail.current.value.trim().toLowerCase(),
                        senha: retval,
                        nome: txtNome.current.value.trim(),
                        sobrenome: txtSobreNome.current.value.trim(),
                        cpf: txtCPF.current.value.trim(),
                        datanascimento: txtDataNascimento.current.value,
                        endereco: txtEndereco.current.value.trim(),
                        complemento: txtComplemento.current.value.trim(),
                        numero: txtNumero.current.value.trim(),
                        bairro: txtBairro.current.value.trim(),
                        cidade: txtCidade.current.value.trim(),
                        estado: selEstado.current.value,
                        telefone: txtTelefone.current.value.trim(),
                        celular: txtMobile.current.value.trim(),
                        cep: txtCEP.current.value.trim()
                    }

                    const response = await API.graphql(graphqlOperation(createCustomerProfile, { input: entryProfile }))
                    console.log(response)

                    dispatch({
                        type: "SignIn",
                        payload: response.data.createCustomerProfile
                    })
                    
                    window.location = "/dashboard";

                    setIsLoading(false);

                }
            }
            catch (e) {
                setIsLoading(false);
                console.log(e)
                alert("Ocorreu um erro inesperado! Tente novamente ou entre em contato com nosso suporte!");
            }
        }

        setValidated(true);
    };

    return (
        <Container id="home" fluid style={{ margin: 0, padding: 0, overflowX: 'hidden' }}>
            <HeaderComponent />
            <Container fluid style={{ margin: 0, padding: 10, marginBottom: 20, backgroundColor: '#2f4a74' }}>
            </Container>
            <Container style={{ marginBottom: 30 }} >
                <Row className="ColLogo" >
                    <Col xs={12} md={7} style={{ margin: 'auto', marginTop: 20, fontSize: '18px', textAlign: 'center' }}>
                        <a className="bodyLinks" href="/signupb2b">
                            <Alert variant='success'>
                                Lojista ou Personal Shopper - Clique aqui .
                        </Alert>
                        </a>
                    </Col>
                    <Col xs={12} md={7} style={{ margin: 'auto' }}>
                        <h3>
                            Crie sua conta
                         </h3>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>

                            <Row className="rowForm">
                                <Col style={{ marginBottom: 15 }}>
                                    Email* <br />
                                    <Form.Control placeholder="Email"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtEmail}
                                        onChange={() => { txtEmail.current.setCustomValidity("") }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }}>
                                    Senha*  <br />
                                    <Form.Control placeholder="Senha"
                                        type="password"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtSenha}
                                        onChange={() => { txtSenha.current.setCustomValidity("") }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Senha inválida. (Deve conter no mínimo 8 caracteres, Maiusculos, minusculos, números e caracteres especiais)
                                    </Form.Control.Feedback>

                                </Col>
                                <Col style={{ marginBottom: 15 }}>
                                    Confirmar Senha*  <br />
                                    <Form.Control placeholder="Confirmar Senha"
                                        type="password"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtConfirmarSenha}
                                        onChange={() => { txtConfirmarSenha.current.setCustomValidity("") }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Senha inválida. A confirmação da senha dever ser igual a senha informada
                                    </Form.Control.Feedback>

                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }}>
                                    Nome* <br />
                                    <Form.Control placeholder="Nome"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtNome}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o nome.
                                    </Form.Control.Feedback>
                                </Col>
                                <Col style={{ marginBottom: 15 }}>
                                    Sobrenome* <br />
                                    <Form.Control placeholder="Sobrenome"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtSobreNome}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o sobrenome.
                                    </Form.Control.Feedback>

                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }}>
                                    CPF (Somente números)* <br />
                                    <Form.Control placeholder="CPF"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtCPF}
                                        onChange={() => { txtCPF.current.setCustomValidity("") }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        CPF Invalido.
                                    </Form.Control.Feedback>

                                </Col>
                                <Col style={{ marginBottom: 15 }}>
                                    Data de nascimento (dd/mm/yyyy) * <br />
                                    <Form.Control placeholder="dd/mm/yyyy"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtDataNascimento}
                                        onChange={() => { txtDataNascimento.current.setCustomValidity("") }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Data inválida.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row >
                                <Col style={{ marginBottom: 15 }}>
                                    Endereço* <br />
                                    <Form.Control placeholder="Endereço"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtEndereco}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o endereço.
                                     </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }}>
                                    Numero* <br />
                                    <Form.Control placeholder="Número"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtNumero}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o endereço.
                                     </Form.Control.Feedback>

                                </Col>
                                <Col md={9} style={{ marginBottom: 15 }}>
                                    Complemento <br />
                                    <Form.Control placeholder="Apartamento, Sala, etc."
                                        ref={txtComplemento}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }} md={5}>
                                    Bairro*  <br />
                                    <Form.Control placeholder="Bairro" type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtBairro}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o bairro.
                                     </Form.Control.Feedback>

                                </Col>
                                <Col style={{ marginBottom: 15 }}>
                                    Cidade* <br />
                                    <Form.Control placeholder="Cidade" type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtCidade}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe a cidade.
                                     </Form.Control.Feedback>

                                </Col>

                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }}>
                                    Estado* <br />
                                    <Form.Control as="select" defaultValue="Estado..."
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={selEstado}
                                        onChange={() => { selEstado.current.setCustomValidity("") }}

                                    >
                                        <option>Estado...</option>
                                        <option value="AC">Acre</option>
                                        <option value="AL">Alagoas</option>
                                        <option value="AP">Amapá</option>
                                        <option value="AM">Amazonas</option>
                                        <option value="BA">Bahia</option>
                                        <option value="CE">Ceará</option>
                                        <option value="DF">Distrito Federal</option>
                                        <option value="ES">Espírito Santo</option>
                                        <option value="GO">Goiás</option>
                                        <option value="MA">Maranhão</option>
                                        <option value="MT">Mato Grosso</option>
                                        <option value="MS">Mato Grosso do Sul</option>
                                        <option value="MG">Minas Gerais</option>
                                        <option value="PA">Pará</option>
                                        <option value="PB">Paraíba</option>
                                        <option value="PR">Paraná</option>
                                        <option value="PE">Pernambuco</option>
                                        <option value="PI">Piauí</option>
                                        <option value="RJ">Rio de Janeiro</option>
                                        <option value="RN">Rio Grande do Norte</option>
                                        <option value="RS">Rio Grande do Sul</option>
                                        <option value="RO">Rondônia</option>
                                        <option value="RR">Roraima</option>
                                        <option value="SC">Santa Catarina</option>
                                        <option value="SP">São Paulo</option>
                                        <option value="SE">Sergipe</option>
                                        <option value="TO">Tocantins</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Selecione o estado.
                                     </Form.Control.Feedback>

                                </Col>
                                <Col style={{ marginBottom: 15 }} md={5}>
                                    CEP*  <br />
                                    <Form.Control placeholder="CEP" type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtCEP}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o CEP.
                                     </Form.Control.Feedback>

                                </Col>
                            </Row>
                            <Row >
                                <Col style={{ marginBottom: 15 }} md={5}>
                                    Telefone Fixo*  <br />
                                    <Form.Control placeholder="Ex. +55 11 1234-1212" type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtTelefone}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o telefone.
                                     </Form.Control.Feedback>

                                </Col>
                                <Col style={{ marginBottom: 15 }} md={5}>
                                    Celular* <br />
                                    <Form.Control placeholder="Ex. +55 11 1234-1212" type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtMobile}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o celular.
                                     </Form.Control.Feedback>

                                </Col>
                            </Row>
                            <Row >
                                <Col style={{ marginBottom: 15 }}>
                                    {
                                        !isLoading ?
                                            <>
                                                <Button variant="primary" type="submit">
                                                    Criar
                                                </Button>
                                            </>
                                            :
                                            <>
                                                <Button variant="primary" type="submit" disabled={true}>
                                                    <Spinner size="sm" animation="border" style={{ marginRight: 10 }} />Criar
                                                </Button>
                                            </>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container >
            <RodapeComponent />

        </Container >
    )
}

export default SignIn;
