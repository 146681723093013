import React from 'react';
import {
    Navbar,
    Nav,
    Image
} from 'react-bootstrap';

import './Components.css'

function Menu() {

    return (

        <Navbar expand="lg" style={{ justifyContent: 'center', marginTop: -30 }} >
            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{marginTop: 15}} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto" style={{ width: '100%', margin: 'auto', padding: 20, backgroundColor: 'white', zIndex:10}}>
                    <Nav.Link href="/#home" className="linkMenu">Home</Nav.Link>
                    <Nav.Link href="/#nossoServicos" className="linkMenu">Serviços</Nav.Link>
                    <Nav.Link href="/#experiencia" className="linkMenu">Experiência</Nav.Link>
                    <Nav.Link href="/#sobreNos" className="linkMenu">Sobre Nós</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )

}

export default Menu;
