import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    Container,
    Image,
    Card,
    Row,
    Col,
    Button,
    Form,
    Spinner
} from 'react-bootstrap';

import './../Components.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'

// import { getAllGiftCards } from '../../graphql/mutations';
import awsconfig from '../../aws-exports';
import { API, graphqlOperation } from "aws-amplify";
import { createPaymentRequest } from './../../graphql/mutations';
import { getCurrencyRate } from './../../graphql/queries';


function CreatePaymentRequestComponent() {

    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile)

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState("");

    const [dataCotacao, setDataCotacao] = useState("carregando...");
    const [cotacao, setCotacao] = useState("carregando...");
    const [simboloMoedaOrigem, setSimboloMoedaOrigem] = useState("");
    const [simboloMoedaDestino, setSimboloMoedaDestino] = useState("");
    const [descricaoMoedaOrigem, setDescricaoMoedaOrigem] = useState("");
    const [descricaoMoedaDestino, setDescricaoMoedaDestino] = useState("");
    const [valorCotacao, setValorCotacao] = useState("");

    const txtEmail = useRef(null);
    const selMoeda = useRef(null);
    const txtValor = useRef(null);
    const txtDescricao = useRef(null);

    const getCurrentRate = async () => {
        const currentRate = await API.graphql(graphqlOperation(getCurrencyRate, { id: "1" }))

        console.log(currentRate.data.getCurrencyRate)

        setDataCotacao(currentRate.data.getCurrencyRate.datacotacao);
        setCotacao(`1 ${currentRate.data.getCurrencyRate.moedaorigem} => ${currentRate.data.getCurrencyRate.valor} ${currentRate.data.getCurrencyRate.moedadestino}`);
        setSimboloMoedaDestino(currentRate.data.getCurrencyRate.moedadestinosimbolo)
        setSimboloMoedaOrigem(currentRate.data.getCurrencyRate.moedaorigemsimbolo)
        setDescricaoMoedaDestino(currentRate.data.getCurrencyRate.moedadestino)
        setDescricaoMoedaOrigem(currentRate.data.getCurrencyRate.moedaorigem)
        setValorCotacao(currentRate.data.getCurrencyRate.valor)

    }


    useEffect(() => {
        getCurrentRate();
    }, [])

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setIsLoading(true);

        // Check if field content is valid
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let regValor = /^[0-9]{0,6}(\,[0-9]{1,2})?$/;

        if (regEmail.test(String(txtEmail.current.value.trim()).toLowerCase()) === false) {
            setError("Email inválido")
            txtEmail.current.setCustomValidity("Invalid field.");
        }
        else
            txtEmail.current.setCustomValidity("");

        if (selMoeda.current.value === "Moeda...")
            selMoeda.current.setCustomValidity("Invalid field.");
        else
            selMoeda.current.setCustomValidity("");

        if (regValor.test(String(txtValor.current.value.trim()).toLowerCase()) === false) {
            txtValor.current.setCustomValidity("Invalid field.");
        }
        else
            txtValor.current.setCustomValidity("");

        if (txtDescricao.current.value.trim() === "")
            txtDescricao.current.setCustomValidity("Invalid field.");
        else
            txtDescricao.current.setCustomValidity("");


        if (form.checkValidity() === false) {
            txtEmail.current.focus();

            setIsLoading(false);

        }
        else {

            try {

                const entryPaymentRequest = {
                    emailcliente: txtEmail.current.value.trim().toLowerCase(),
                    moedaorigemsimbolo: simboloMoedaOrigem,
                    moedaorigem: descricaoMoedaOrigem,
                    moedadestinosimbolo: simboloMoedaDestino,
                    moedadestino: descricaoMoedaDestino,
                    valorrequerido: txtValor.current.value.trim(),
                    valorcotacao: valorCotacao,
                    descricao: txtDescricao.current.value.trim(),
                    personalshopperb2b: {
                        id: profile.content.id,
                        nome: profile.content.nome,
                        sobrenome: profile.content.sobrenome,
                        nomeloja: profile.content.nomeloja
                    },
                    status: "Pendente",
                    moedarequirida: selMoeda.current.value,
                    datageracao: new Date().toISOString()
                }

                console.log(entryPaymentRequest);

                const response = await API.graphql(graphqlOperation(createPaymentRequest, { input: entryPaymentRequest }))
                console.log(response)

                window.location = "/dashboard";

                setIsLoading(false);


            }
            catch (e) {
                console.log(e)
                setIsLoading(false);
                alert(e.stack);
            }
        }

        setValidated(true);
    };

    return (

        <Container fluid style={{ margin: 0, padding: 20, backgroundColor: 'white', marginBottom: 10 }}>
            <Row>
                <Col xs={12} lg={12} md={12} className="titleSessionProduct" style={{ margin: 'auto' }} >
                    <h5>Nova solicitacao de pagamento</h5>
                </Col>
            </Row>
            <Row style={{ marginBottom: 40 }}>

                <Col xs={12} lg={12} md={12} style={{ margin: 'auto' }} >
                    <FontAwesomeIcon icon={faChartLine} size={50} color={"#2f4a74"} style={{ fontSize: '20px' }} />
                    {`  Cotação Dolar Turismo - Ultima atualização ${dataCotacao} - Fonte Jornal Valor Econômico`}
                </Col>
            </Row>

            <Row style={{ marginBottom: 40 }}>

                <Col xs={12} lg={12} md={12} style={{ margin: 'auto', fontSize: '20px' }} >
                    {cotacao}
                </Col>
            </Row>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>

                    <Col xs={12} lg={12} md={12} style={{ margin: 'auto' }} >
                        <FontAwesomeIcon icon={faCheckCircle} size={50} color={"#2f4a74"} style={{ fontSize: '20px' }} />
                    &nbsp; Passo 1 - Informe email para envio da requisição de pagamento
                    <Container fluid style={{ marginLeft: 20, marginTop: 10 }}>
                            <Row>
                                <Col md={8}>
                                    <Form.Control placeholder="Email"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtEmail}
                                        onChange={() => { txtEmail.current.setCustomValidity("") }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Email inválido.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>

                    <Col xs={12} lg={12} md={12} style={{ margin: 'auto' }} >
                        <FontAwesomeIcon icon={faCheckCircle} size={50} color={"#2f4a74"} style={{ fontSize: '20px' }} />
                    &nbsp; Passo 2 - Selecione a moeda
                    <Container fluid style={{ marginLeft: 20, marginTop: 10 }}>
                            <Row>
                                <Col md={4} style={{ marginBottom: 15 }}>
                                    <Form.Control as="select" defaultValue="Estado..."
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={selMoeda}
                                        onChange={() => { selMoeda.current.setCustomValidity("") }}

                                    >
                                        <option>Moeda...</option>
                                        <option value={simboloMoedaOrigem}>{descricaoMoedaOrigem}</option>
                                        <option value={simboloMoedaDestino}>{descricaoMoedaDestino}</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Selecione o estado.
                                     </Form.Control.Feedback>

                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>

                    <Col xs={12} lg={12} md={12} style={{ margin: 'auto' }} >
                        <FontAwesomeIcon icon={faCheckCircle} size={50} color={"#2f4a74"} style={{ fontSize: '20px' }} />
                    &nbsp; Passo 3 - Informe o valor
                    <Container fluid style={{ marginLeft: 20, marginTop: 10 }}>
                            <Row>
                                <Col md={4}>
                                    <Form.Control placeholder="Valor"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtValor}
                                        onChange={() => { txtValor.current.setCustomValidity("") }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Valor inválido.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>

                    <Col xs={12} lg={12} md={12} style={{ margin: 'auto' }} >
                        <FontAwesomeIcon icon={faCheckCircle} size={50} color={"#2f4a74"} style={{ fontSize: '20px' }} />
                    &nbsp; Passo 4 - Adicione uma descrição que será exibida para seu cliente
                    <Container fluid style={{ marginLeft: 20, marginTop: 10 }}>
                            <Row>
                                <Col md={8}>
                                    <Form.Control placeholder="Descrição"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtDescricao}
                                        onChange={() => { txtDescricao.current.setCustomValidity("") }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Descrição inválida.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Col style={{ marginBottom: 15 }}>
                        {
                            !isLoading ?
                                <>
                                    <Button variant="primary" type="submit">
                                        Enviar
                                                </Button>
                                </>
                                :
                                <>
                                    <Button variant="primary" type="submit" disabled={true}>
                                        <Spinner size="sm" animation="border" style={{ marginRight: 10 }} />Criar
                                                </Button>
                                </>
                        }
                    </Col>
                </Row>
            </Form>
        </Container>
    )

}

export default CreatePaymentRequestComponent;
