import { combineReducers } from 'redux';

const INITIAL_STATE = {
  content: undefined
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SignIn":
      return {
        ...state,
        content: action.payload
      }
    case "SignOut":
      return {
        ...state,
        content: action.payload
      }
    default:
      return state
  }
};

export default profileReducer;