import { combineReducers } from "redux";


import profile from './ProfileReducer'
import giftCard from './GiftCardsReducer'
import paymentRequest from './PaymentRequestsReducer'
import exchangeRate from './ExchangeRateReducer'


const rootReducer = combineReducers({
    profile,
    giftCard,
    exchangeRate,
    paymentRequest
})

export default rootReducer
