import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux'
import {
    Container,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
    Button,
    Spinner,
    Alert
} from 'react-bootstrap';
import Menu from './../components/Menu'
import RodapeComponent from './../components/Rodape'
import HeaderComponent from './../components/Header'
import NossosProdutosComponent from './../components/NossosProdutos'
import { Redirect } from 'react-router-dom';
import './Pages.css'

import { listCustomerProfiles } from './../graphql/queries';
import awsconfig from './../aws-exports';
import { API, graphqlOperation } from "aws-amplify";
import crypto from "crypto";

API.configure(awsconfig);

function SignIn({ navigation }) {

    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const [emailError, setEmailError] = useState("");
    const [senhaError, setSenhaError] = useState("Senha inválida!");

    const txtEmail = useRef(null);
    const txtSenha = useRef(null);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setIsLoading(true);

        // Check if field content is valid
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (regEmail.test(String(txtEmail.current.value.trim()).toLowerCase()) === false) {
            setEmailError("Email inválido")
            txtEmail.current.setCustomValidity("Invalid field.");
        }
        else
            txtEmail.current.setCustomValidity("");

        if (form.checkValidity() === false) {
            txtEmail.current.focus();
            setIsLoading(false);

        }
        else {


            try {

                let cipher = crypto.createCipher("aes256", txtSenha.current.value);
                let retval = cipher.update(txtEmail.current.value.trim().toLowerCase(), 'utf8', 'hex');
                retval += cipher.final('hex');
                console.log(retval)
                // Check if email already used
                const listFilterProfile = {
                    email: {
                        eq: txtEmail.current.value.trim().toLowerCase()
                    },
                    senha: {
                        eq: retval
                    }
                    
                }


                const profiles = await API.graphql(graphqlOperation(listCustomerProfiles, { filter: listFilterProfile }))
                if (profiles.data.listCustomerProfiles.items.length === 0) {
                    setIsLoading(false);
                    setIsError(true);
                    setSenhaError("Login inválido!");
                    txtSenha.current.setCustomValidity("Invalid field.");
                    txtSenha.current.focus();
                }
                else {
                    dispatch({
                        type: "SignIn",
                        payload: profiles.data.listCustomerProfiles.items[0]
                    })
                    setIsLoading(false);
                    window.location = "/dashboard";

                }
            }
            catch (e) {
                console.log(e)
            }
        }

        setValidated(true);
    };
    return (
        <Container id="home" fluid style={{ margin: 0, padding: 0, overflowX: 'hidden' }}>
            <HeaderComponent />
            <Container fluid style={{ margin: 0, padding: 10, marginBottom: 20, backgroundColor: '#2f4a74' }}>
            </Container>
            <Container className="siginContainer" style={{ marginBottom: 30 }}>
                <Row className="rowForm" >
                    <Col xs={12} md={7} style={{ margin: 'auto' }}>
                        <h3>
                            Acesse sua conta
            </h3>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                Email* <br />
                                <Form.Control placeholder="Email"
                                    type="text"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    ref={txtEmail}
                                    onChange={() => { txtEmail.current.setCustomValidity("") }}

                                />
                                <Form.Control.Feedback type="invalid">
                                    {emailError}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                Senha*  <br />
                                <Form.Control placeholder="Senha"
                                    type="password"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    ref={txtSenha}
                                    onChange={() => { txtSenha.current.setCustomValidity("") }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {senhaError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {
                                !isLoading ?
                                    <>
                                        <Button variant="primary" type="submit">
                                            Entrar
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button variant="primary" type="submit" disabled={true}>
                                            <Spinner size="sm" animation="border" style={{ marginRight: 10 }} />Entrar
                                        </Button>
                                    </>
                            }
                        </Form>
                    </Col>
                </Row>

                <Row className="rowForm">
                    <Col xs={12} md={7} style={{ margin: 'auto' }}>
                        Caso ainda não tenha uma conta, <a className="bodyLinks" href="/signup">Clique aqui</a> .
                    </Col>
                </Row>

            </Container >

            <NossosProdutosComponent />
            <RodapeComponent />

        </Container>
    )
}

export default SignIn;
