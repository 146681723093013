import React from 'react';
import {
    Container,
    Image,
    Card,
    Row,
    Col,
    Carousel

} from 'react-bootstrap';

import './Components.css'

function NossaExperiencia() {

    return (
        <Container id="experiencia" fluid style={{ padding: 0, padding: 20, marginBottom: 40 }}>
            <Row>
                <Col xs={12} className="titleSession" style={{ margin: 'auto' }} >
                    Nossa Experiência
                </Col>
            </Row>
            <Row style={{ padding: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Col xs={12} lg={3} md={4} style={{ color: '#024325', marginBottom: 20 }} >
                    <Container style={{ color: '#2f4a74' }}>
                        <Image src="https://images.pexels.com/photos/3183183/pexels-photo-3183183.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" fluid style={{ height: 300, width: '100%', objectFit: 'cover' }} />
                    </Container>
                </Col>
                <Col xs={12} lg={8} md={8} style={{ marginLeft: 10, marginBottom: 20, alignSelf: 'center' }}>
                    <Card style={{ width: 'auto', height: 'auto', border: 0 }}>
                        <Card.Body>
                            <Card.Title ></Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Com duas décadas de dedicação, não há necessidade de preocupações ao confiar a busca de sua potencial dupla cidadania italiana a mãos inseguras. Ao longo desses 20 anos, colaboramos com uma ampla gama de clientes provenientes de diversas origens, oferecendo suporte especializado no processo de obtenção da cidadania italiana. Se o seu objetivo é alcançar a cidadania italiana por meio do casamento (jure matrimonii) ou da descendência (jure sanguinis), estamos aqui para guiá-lo em sua jornada, fornecendo informações essenciais para sua chegada à Itália sem contratempos e sem estresse.
                                Orgulhamo-nos de ser especialistas em cidadania italiana e de mantermos uma atualização constante em relação à legislação italiana em evolução. Nossa equipe de especialistas está profundamente familiarizada com a sua situação específica, assegurando que faremos o que é melhor para você e proporcionaremos à sua solicitação de cidadania a melhor oportunidade possível.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>



        </Container>

        // <Container id="experiencia" fluid style={{ margin: 0, padding: 20, backgroundColor: '#fff', marginBottom: 10 }}>
        //     <Row>
        //         <Col xs={12} style={{ margin: 'auto' }} >
        //             <Image src="https://images.pexels.com/photos/8204386/pexels-photo-8204386.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" fluid style={{ height: 300, width: '100%', objectFit: 'cover', opacity: '30%'}} />
        //         </Col>
        //     </Row>

        //     <Row>
        //         <Col xs={12} className="titleSession" style={{ margin: 'auto' }} >
        //             Nossa Experiência
        //         </Col>
        //     </Row>
        //     <Row>

        //         <Col xs={12} style={{ margin: 'auto' }} >
        //             Com duas décadas de dedicação, não há necessidade de preocupações ao confiar a busca de sua potencial dupla cidadania italiana a mãos inseguras. Ao longo desses 20 anos, colaboramos com uma ampla gama de clientes provenientes de diversas origens, oferecendo suporte especializado no processo de obtenção da cidadania italiana. Se o seu objetivo é alcançar a cidadania italiana por meio do casamento (jure matrimonii) ou da descendência (jure sanguinis), estamos aqui para guiá-lo em sua jornada, fornecendo informações essenciais para sua chegada à Itália sem contratempos e sem estresse.
        //             Orgulhamo-nos de ser especialistas em cidadania italiana e de mantermos uma atualização constante em relação à legislação italiana em evolução. Nossa equipe de especialistas está profundamente familiarizada com a sua situação específica, assegurando que faremos o que é melhor para você e proporcionaremos à sua solicitação de cidadania a melhor oportunidade possível.
        //         </Col>

        //     </Row>

        // </Container>
    )

}

export default NossaExperiencia;
