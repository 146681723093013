import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    Container,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
    Button,
    Spinner,
} from 'react-bootstrap';
import Menu from './../components/Menu'
import LeftMenuUserLogged from './../components/LeftMenuUserLogged'
import RodapeComponent from './../components/Rodape'
import HeaderComponent from './../components/Header'
import NossosProdutosComponent from './../components/NossosProdutos'
import CartaoServicoComponent from './../components/UserLogged/CartaoServico'
import GiftCardServicoComponent from './../components/UserLogged/GiftCardsServico'
import PaymentRequestsServico from '../components/UserLogged/PaymentRequestsServico';
import '../pages/Pages.css'

// import { signIn } from './../graphql/mutations';
import awsconfig from './../aws-exports';
import { API, graphqlOperation } from "aws-amplify";
import { Redirect } from 'react-router-dom';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

import PaypalExpressBtn from 'react-paypal-express-checkout';


API.configure(awsconfig);

function Dashboard({ navigation }) {

    const profile = useSelector(state => state.profile);

    const [numeroValido, setNumeroValido] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const txtEmail = useRef(null);
    const txtThunk = useRef(null);

    const handleSubmit = async (event) => {

        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);
        const crypto = require("crypto");


        if (form.checkValidity() === false) {
            setIsLoading(false);
        }
        else {

            var decipher = crypto.createDecipher('aes-256-ctr', txtEmail.current.value)
            var dec = decipher.update(txtThunk.current.value, 'hex', 'utf8')
            dec += decipher.final('utf8');

            setNumeroValido(dec);
            setIsLoading(false);


        }

        setValidated(true);

    }

    useEffect(() => {

        // window.paypal.Buttons().render('#paypalterrellbanx');
            window.paypal.Buttons({
                createOrder: function (data, actions) {
                    // This function sets up the details of the transaction, including the amount and line item details.
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: '60.00'
                            }
                        }]
                    });
                }
            }).render('#paypalterrellbanx');

    }, [])

    const onSuccess = (payment) => {
        // Congratulation, it came here means everything's fine!
        console.log("The payment was succeeded!", payment);
        // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    }

    const onCancel = (data) => {
        // User pressed "cancel" or close Paypal's popup!
        console.log('The payment was cancelled!', data);
        // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    }

    const onError = (err) => {
        // The main Paypal's script cannot be loaded or somethings block the loading of that script!
        console.log("Error!", err);
        // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
        // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    }

    let env = 'sandbox'; // you can set here to 'production' for production
    let currency = 'BRL'; // or you can set this value from your props or state
    let total = 60; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
    // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

    const client = {
        sandbox: 'Any4um6QRKTYyoX.7luqMWLxU.iNAhUBr-sktO473K199YoiDiJ4q6zT',
        production: 'YOUR-PRODUCTION-APP-ID',
    }
    return (
        <>
            <Container id="home" fluid style={{ margin: 0, padding: 0, overflowX: 'hidden' }}>
                <HeaderComponent />
                <Container id="paypalterrellbanx" fluid style={{ margin: 0, padding: 10, backgroundColor: '#2f4a74' }}>
                </Container>
                <Container fluid style={{ marginBottom: 30 }}>



                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="rowForm">
                            <Col style={{ marginBottom: 15 }}>
                                Thunk <br />
                                <Form.Control placeholder="Thunk"
                                    type="text"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    ref={txtThunk}
                                    onChange={() => { txtThunk.current.setCustomValidity("") }}

                                />
                            </Col>
                        </Row>
                        <Row className="rowForm">
                            <Col style={{ marginBottom: 15 }}>
                                Email <br />
                                <Form.Control placeholder="Email"
                                    type="text"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    ref={txtEmail}
                                    onChange={() => { txtEmail.current.setCustomValidity("") }}

                                />
                            </Col>
                        </Row>

                        <Row >
                            <Col style={{ marginBottom: 15 }}>
                                {
                                    !isLoading ?
                                        <>
                                            <Button variant="primary" type="submit">
                                                Get Thunk
                                        </Button>
                                        </>
                                        :
                                        <>
                                            <Button variant="primary" type="submit" disabled={true}>
                                                <Spinner size="sm" animation="border" style={{ marginRight: 10 }} />Get Thunk
                                        </Button>
                                        </>
                                }
                            </Col>
                        </Row>
                    </Form>

                    <Row>
                        <Col style={{ marginBottom: 15 }}>
                            CC: {numeroValido}
                        </Col>
                    </Row>
                </Container >

                <NossosProdutosComponent />
                <RodapeComponent />

            </Container>


        </>
    )
}

export default Dashboard;
