import React from 'react';
import {
    Container,
    Image,
    Card,
    Row,
    Col
} from 'react-bootstrap';

import './Components.css'

function SobreNos() {

    return (
        <Container id="sobreNos" fluid style={{ margin: 0, padding: 20, backgroundColor: '#fff', marginBottom: 10 }}>
            <Row>
                <Col xs={12} className="titleSession" style={{ margin: 'auto' }} >
                    Sobre Nós
                </Col>
            </Row>

            <Row>
                <Col xs={12} style={{ margin: 'auto' }} >
                    <Card style={{ width: 'auto', height: 'auto', border: 0 }}>
                        <Card.Body>
                            <Card.Title >Conexão Cultural - Explorando Vínculos Culturais</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Na Legge, compreendemos profundamente o valor dos laços culturais e o desejo de reconectar-se com as raízes italianas. Nossa equipe, além de sua competência jurídica, possui uma sensibilidade cultural que vai além, estabelecendo uma conexão autêntica com nossos clientes e enriquecendo sua experiência de maneira única. Estamos comprometidos não apenas em fornecer suporte legal, mas também em tornar sua jornada mais significativa, valorizando a riqueza da herança italiana. Junte-se a nós para uma experiência que transcende o aspecto legal, proporcionando uma imersão genuína na cultura e tradição italianas.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{ margin: 'auto' }} >
                    <Card style={{ width: 'auto', height: 'auto', border: 0 }}>
                        <Card.Body>
                            <Card.Title >Conectando Gerações por meio da cidadania.</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                A Legge tornará a jornada para a cidadania italiana uma experiência gratificante e enriquecedora, fazendo a ponte entre o passado e o presente para os descendentes de imigrantes italianos.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{ margin: 'auto' }} >
                    <Card style={{ width: 'auto', height: 'auto', border: 0 }}>
                        <Card.Body>
                            <Card.Title >Missão e Valores</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Na Legge, nossa missão é proporcionar uma reconexão significativa dos indivíduos com sua herança italiana, através da oferta de serviços jurídicos abrangentes para a obtenção da cidadania italiana. Buscamos incessantemente simplificar o processo de solicitação de cidadania, assegurando uma jornada tranquila e eficiente para nossos clientes. Estamos empenhados em não apenas oferecer serviços, mas em facilitar uma experiência enriquecedora que celebra as raízes e a identidade italianas de cada cliente.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{ margin: 'auto' }} >
                    <Card style={{ width: 'auto', height: 'auto', border: 0 }}>
                        <Card.Body>
                            <Card.Title >Padrões Éticos - Compromisso com a Ética Profissional</Card.Title>
                            <Card.Text style={{textAlign: 'justify'}}>
                                Na Legge, abraçamos os mais altos padrões éticos no exercício da advocacia. A confidencialidade, transparência e integridade do cliente são valores centrais que estabelecem uma parceria confiável e sólida durante todo o processo de solicitação de cidadania. Conte conosco para uma experiência jurídica ética e transparente.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    )

}

export default SobreNos;
