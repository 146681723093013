const INITIAL_STATE = {
  exchangeRateValue: undefined
};

const exchangeRateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GetCurrentExchangeRate":
      return {
        ...state,
        exchangeRateValue: action.payload
      }
    default:
      return state
  }
};

export default exchangeRateReducer;