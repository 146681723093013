import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    Container,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
    Button,
    Spinner,
} from 'react-bootstrap';
import Menu from '../components/Menu'
import LeftMenuUserLogged from '../components/LeftMenuUserLogged'
import RodapeComponent from '../components/Rodape'
import HeaderComponent from '../components/Header'
import NossosProdutosComponent from '../components/NossosProdutos'
import CartaoServicoComponent from '../components/UserLogged/CartaoServico'
import CreatePaymentRequestComponent from '../components/B2BLogged/CreatePaymentRequest'
import GiftCardServicoComponent from '../components/UserLogged/GiftCardsServico'
import './Pages.css'

// import { getExchangeRate } from '../graphql/mutations';
import awsconfig from '../aws-exports';
import { API, graphqlOperation } from "aws-amplify";
import { Redirect } from 'react-router-dom';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';


API.configure(awsconfig);

function CheckoutStandardCheckout({ navigation }) {

    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile);
    const exchangeRate = useSelector(state => state.exchangeRate);

    const getExchangeRate = async () => {

        // const entry = {
        //     loja: 'all'
        // }
        // const response = await API.graphql(graphqlOperation(getExchangeRate, entry))
        // console.log(response)

        // if (JSON.parse(response.data.getExchangeRate).message !== "Success") {
        //     alert(JSON.parse(response.data.getExchangeRate).message);
        // }
        // else {
        //     dispatch({
        //         type: "GetCurrentExchangeRate",
        //         payload: JSON.parse(response.data.getExchangeRate).item
        //     })
        // }


    }


    // useEffect(() => {
    //     getExchangeRate();
    // }, [])

    return (
        <>
            {!profile.content ?
                <Redirect to="/" />
                :
                <Container id="home" fluid style={{ margin: 0, padding: 0, overflowX: 'hidden' }}>
                    <HeaderComponent />
                    <Container fluid style={{ margin: 0, padding: 10, backgroundColor: '#2f4a74' }}>
                    </Container>
                    <Container fluid style={{ marginBottom: 30 }}>
                        <Row>
                            <Col xs={12} md={3} style={{ marginBottom: 20, padding: 20, widht: '100%', backgroundColor: '#f6f6f6' }}>
                                <LeftMenuUserLogged activepage={"CreatePaymentRequest"} />
                            </Col>
                            <Col xs={12} md={9} style={{ margin: 'auto' }}>
                                <CreatePaymentRequestComponent />
                            </Col>
                        </Row>


                    </Container >

                    <NossosProdutosComponent />
                    <RodapeComponent />

                </Container>
            }

        </>
    )
}

export default CheckoutStandardCheckout;
