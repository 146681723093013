import React from 'react';
import {
    Container,
    Image,
    Carousel
} from 'react-bootstrap';

import './Components.css'

function Menu() {
    return (

        <Container fluid style={{ padding: 0, marginBottom: 40 }}>
            <Carousel>
                {/* <Carousel.Item style={{ backgroundColor: '#2f4a74' }}>
                
                    <Image src="https://images.pexels.com/photos/532263/pexels-photo-532263.jpeg" fluid style={{ height: 500, width: '100%', objectFit: 'cover' }} />
                    <Carousel.Caption style={{ bottom: 100 }}>
                        <h3 style={{ fontSize: 50 }}>TERRELLBANX</h3>
                        <h3 style={{ fontSize: 30 }}>Sua solução para comprar, comer, viajar e se divertir fora do Brasil</h3>
                    </Carousel.Caption>
                </Carousel.Item> */}
                <Carousel.Item>
                    <Image src="https://images.pexels.com/photos/2748019/pexels-photo-2748019.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" fluid style={{ height: 700, width: '100%', objectFit: 'cover' }} />

                    <Carousel.Caption>
                        <h3>Conectando Gerações por meio da cidadania</h3>
                        <p>Sabemos que o caminho pode parecer longo, mas estamos aqui para tornar cada etapa mais fácil para você. Descubra como podemos ajudar:</p>
                        <div className="divBanner" >
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                {/* <Carousel.Item>

                    <Image src="https://images.pexels.com/photos/1010657/pexels-photo-1010657.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" fluid style={{ height: 500, width: '100%', objectFit: 'cover' }} />
                    <Carousel.Caption>
                        <h3>PARCELE SEUS PASSEIOS, EVENTOS, SHOWS E MAIS...</h3>
                        <p>Já está no seu destino e quer parcelar um show, um evento, um passeio? Saiba mais.</p>
                        <div className="divBanner">
                        </div>
                    </Carousel.Caption>
                </Carousel.Item> */}
            </Carousel>
        </Container>
    )

}

export default Menu;
