const INITIAL_STATE = {
  giftCards: []
};

const giftCardsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GetAllGiftCards":
      return {
        ...state,
        giftCards: action.payload
      }
    default:
      return state
  }
};

export default giftCardsReducer;