import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux'

import {
    Container,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
    Button,
    Spinner,
    Alert
} from 'react-bootstrap';
import Menu from '../components/Menu'
import './Pages.css'
import './../components/Components.css'
import axios from 'axios';

import RodapeComponent from '../components/Rodape'
import HeaderComponent from '../components/Header'

import { createCustomerProfile } from './../graphql/mutations';
import { listCustomerProfiles } from './../graphql/queries';
import awsconfig from '../aws-exports';
import { API, graphqlOperation } from "aws-amplify";
import crypto from "crypto";

API.configure(awsconfig);

function SignInB2B() {

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState("");
    const [logoError, setLogoError] = useState("");

    const txtDataNascimento = useRef(null);
    const txtEmail = useRef(null);
    const txtSenha = useRef(null);
    const txtNome = useRef(null);
    const txtSobreNome = useRef(null);
    const txtEndereco = useRef(null);
    const txtComplemento = useRef(null);
    const txtCEP = useRef(null);
    const txtCidade = useRef(null);
    const txtTelefone = useRef(null);
    const txtMobile = useRef(null);
    const selEstado = useRef(null);
    const txtRoutingNumber = useRef(null);
    const txtAccountNumber = useRef(null);
    const txtConfirmarSenha = useRef(null);
    const filLogo = useRef(null);
    const txtLogo = useRef(null);
    const txtNomeLoja = useRef(null);


    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setIsLoading(true);

        // Check if field content is valid
        let regPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let regDataNascimento = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

        if (regEmail.test(String(txtEmail.current.value.trim()).toLowerCase()) === false) {
            setError("Email inválido")
            txtEmail.current.setCustomValidity("Invalid field.");
        }
        else
            txtEmail.current.setCustomValidity("");

        if (regPassword.test(String(txtSenha.current.value)) === false)
            txtSenha.current.setCustomValidity("Invalid field.");
        else
            txtSenha.current.setCustomValidity("");

        if (regDataNascimento.test(String(txtDataNascimento.current.value)) === false)
            txtDataNascimento.current.setCustomValidity("Invalid field.");
        else
            txtDataNascimento.current.setCustomValidity("");


        if (selEstado.current.value === "Estado...")
            selEstado.current.setCustomValidity("Invalid field.");
        else
            selEstado.current.setCustomValidity("");

        if (txtSenha.current.value !== txtConfirmarSenha.current.value)
            txtConfirmarSenha.current.setCustomValidity("Invalid field.");
        else
            txtConfirmarSenha.current.setCustomValidity("");


        if (form.checkValidity() === false) {
            txtEmail.current.focus();

            setIsLoading(false);

        }
        else {

            try {

                // Check if email already used
                const listFilterProfile = {
                    email: {
                        eq: txtEmail.current.value.trim().toLowerCase()
                    }
                }


                const profiles = await API.graphql(graphqlOperation(listCustomerProfiles, { filter: listFilterProfile }))
                if (profiles.data.listCustomerProfiles.items.length > 0) {
                    setError("Email inválido")
                    txtEmail.current.setCustomValidity("Invalid field.");
                    setIsLoading(false);
                    setIsError(true);
                    alert('Email já cadastrado!')
                }
                else {

                    let cipher = crypto.createCipher("aes256", txtSenha.current.value);
                    let retval = cipher.update(txtEmail.current.value.trim().toLowerCase(), 'utf8', 'hex');
                    retval += cipher.final('hex');

                    const entryProfile = {
                        email: txtEmail.current.value.trim().toLowerCase(),
                        senha: retval,
                        nome: txtNome.current.value.trim(),
                        sobrenome: txtSobreNome.current.value.trim(),
                        datanascimento: txtDataNascimento.current.value,
                        endereco: txtEndereco.current.value.trim(),
                        complemento: txtComplemento.current.value.trim(),
                        cidade: txtCidade.current.value.trim(),
                        estado: selEstado.current.value,
                        telefone: txtTelefone.current.value.trim(),
                        celular: txtMobile.current.value.trim(),
                        cep: txtCEP.current.value.trim(),
                        routingnumber: txtRoutingNumber.current.value.trim(),
                        accountnumber: txtAccountNumber.current.value.trim(),
                        nomeloja: txtNomeLoja.current.value.trim(),
                        b2b: 'true'
                    }

                    const response = await API.graphql(graphqlOperation(createCustomerProfile, { input: entryProfile }))
                    console.log(response)

                    if (filLogo.current.files.length > 0) {

                        let formData = new FormData();
                        formData.append('file', filLogo.current.files[0]);
                        formData.append('key', response.data.createCustomerProfile.id);
                        formData.append('filename', 'logo');

                        await axios.post(`http://localhost:4000/api/upload`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        });
                    }


                    dispatch({
                        type: "SignIn",
                        payload: response.data.createCustomerProfile
                    })

                    window.location = "/dashboard";

                    setIsLoading(false);

                }

            }
            catch (e) {
                console.log(e)
                setIsLoading(false);
                alert(e.stack);
            }
        }

        setValidated(true);
    };

    return (
        <Container id="home" fluid style={{ margin: 0, padding: 0, overflowX: 'hidden' }}>
            <HeaderComponent />
            <Container fluid style={{ margin: 0, padding: 10, marginBottom: 20, backgroundColor: '#2f4a74' }}>
            </Container>
            <Container style={{ marginBottom: 30 }} >
                <Row className="ColLogo" >
                    <Col xs={12} md={7} style={{ margin: 'auto', marginTop: 20, fontSize: '18px', textAlign: 'center' }}>
                        <a className="bodyLinks" href="/signup">
                            <Alert variant='success'>
                                Pessoa Física - Clique aqui .
                        </Alert>
                        </a>
                    </Col>
                    <Col xs={12} md={7} style={{ margin: 'auto' }}>
                        <h3>
                            Lojista ou Personal Shopper - Crie sua conta
                         </h3>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>

                            <Row className="rowForm">
                                <Col style={{ marginBottom: 15 }}>
                                    Email* <br />
                                    <Form.Control placeholder="Email"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtEmail}
                                        onChange={() => { txtEmail.current.setCustomValidity("") }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }}>
                                    Senha*  <br />
                                    <Form.Control placeholder="Senha"
                                        type="password"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtSenha}
                                        onChange={() => { txtSenha.current.setCustomValidity("") }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Senha inválida. (Deve conter no mínimo 8 caracteres, Maiusculos, minusculos, números e caracteres especiais)
                                    </Form.Control.Feedback>

                                </Col>
                                <Col style={{ marginBottom: 15 }}>
                                    Confirmar Senha*  <br />
                                    <Form.Control placeholder="Confirmar Senha"
                                        type="password"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtConfirmarSenha}
                                        onChange={() => { txtConfirmarSenha.current.setCustomValidity("") }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Senha inválida. A confirmação da senha dever ser igual a senha informada
                                    </Form.Control.Feedback>

                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }}>
                                    Nome da loja <br />
                                    <Form.Control placeholder="Nome"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtNomeLoja}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }}>
                                    Nome* <br />
                                    <Form.Control placeholder="Nome"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtNome}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o nome.
                                    </Form.Control.Feedback>
                                </Col>
                                <Col style={{ marginBottom: 15 }}>
                                    Sobrenome* <br />
                                    <Form.Control placeholder="Sobrenome"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtSobreNome}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o sobrenome.
                                    </Form.Control.Feedback>

                                </Col>
                            </Row>
                            <Row >
                                <Col style={{ marginBottom: 15 }}>
                                    Endereço* <br />
                                    <Form.Control placeholder="Endereço"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtEndereco}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o endereço.
                                     </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }}>
                                    Complemento <br />
                                    <Form.Control placeholder="Apartamento, Sala, etc."
                                        ref={txtComplemento}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: 15 }}>
                                    Cidade* <br />
                                    <Form.Control placeholder="Cidade" type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtCidade}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe a cidade.
                                     </Form.Control.Feedback>

                                </Col>
                                <Col style={{ marginBottom: 15 }}>
                                    Estado* <br />
                                    <Form.Control as="select" defaultValue="Estado..."
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={selEstado}
                                        onChange={() => { selEstado.current.setCustomValidity("") }}

                                    >
                                        <option>Estado...</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Selecione o estado.
                                     </Form.Control.Feedback>

                                </Col>
                            </Row>
                            <Row>

                                <Col style={{ marginBottom: 15 }} md={5}>
                                    CEP*  <br />
                                    <Form.Control placeholder="CEP" type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtCEP}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o CEP.
                                     </Form.Control.Feedback>

                                </Col>
                                <Col style={{ marginBottom: 15 }}>
                                    Data de nascimento (dd/mm/yyyy) * <br />
                                    <Form.Control placeholder="dd/mm/yyyy"
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        ref={txtDataNascimento}
                                        onChange={() => { txtDataNascimento.current.setCustomValidity("") }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Data inválida.
                                    </Form.Control.Feedback>
                                </Col>


                            </Row>
                            <Row >
                                <Col style={{ marginBottom: 15 }} md={5}>
                                    Telefone Fixo*  <br />
                                    <Form.Control placeholder="Ex. +55 11 1234-1212" type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtTelefone}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o telefone.
                                     </Form.Control.Feedback>

                                </Col>
                                <Col style={{ marginBottom: 15 }} md={5}>
                                    Celular* <br />
                                    <Form.Control placeholder="Ex. +55 11 1234-1212" type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtMobile}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o celular.
                                     </Form.Control.Feedback>

                                </Col>
                            </Row>
                            <Row style={{ padding: 30 }}>
                                <Form.File id="formcheck-api-regular">
                                    <Form.File.Label>Envie seu logo caso tenha. Ele irá aparecer nas comunicações com seus clientes</Form.File.Label>
                                    <Form.File.Input ref={filLogo} />
                                </Form.File>
                                <Form.Control placeholder=""
                                    type="text"
                                    aria-describedby="inputGroupPrepend"
                                    style={{ display: 'none' }}
                                    ref={txtLogo}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {logoError}
                                </Form.Control.Feedback>

                            </Row>
                            <Row style={{ marginTop: 30 }}>
                                <Col style={{ marginBottom: 15, fontSize: '25px' }} md={5}>
                                    Dados Bancários
                                </Col>
                            </Row>
                            <Row >

                                <Col style={{ marginBottom: 15 }} md={5}>
                                    Número de endereçamento bancário (Routing Number)  <br />
                                    <Form.Control placeholder="123456789" type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtRoutingNumber}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o número de endereçamento bancaria (Routing number).
                                     </Form.Control.Feedback>

                                </Col>
                                <Col style={{ marginBottom: 15 }} md={5}>
                                    Número da conta (Account Number) <br />
                                    <Form.Control placeholder="123456789" type="text"
                                        aria-describedby="inputGroupPrepend"
                                        ref={txtAccountNumber}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Informe o número da conta (Account Number).
                                     </Form.Control.Feedback>

                                </Col>
                            </Row>
                            <Row >
                                <Col style={{ marginBottom: 15 }}>
                                    {
                                        !isLoading ?
                                            <>
                                                <Button variant="primary" type="submit">
                                                    Criar
                                                </Button>
                                            </>
                                            :
                                            <>
                                                <Button variant="primary" type="submit" disabled={true}>
                                                    <Spinner size="sm" animation="border" style={{ marginRight: 10 }} />Criar
                                                </Button>
                                            </>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container >
            <RodapeComponent />

        </Container >
    )
}

export default SignInB2B;
