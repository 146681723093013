import React from 'react';
import {
    Container,
    Image,
    Row,
    Col
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import WhatsAppBotao from './../assets/whatsapp-botao-1024x360.png'

import './Components.css'

function Rodape({ fixedBottom }) {



    return (

        <Container fluid style={fixedBottom ? { margin: 0, padding: 20, backgroundColor: '#2f4a74', position: 'absolute', bottom: 0 } : { margin: 0, padding: 20, backgroundColor: '#19534C' }}>
            <Row>
                <Col xs={12} md={4} className="Logo" style={{ margin: 'auto', color: 'white', fontSize: '20px', fontWeight: 500, marginBottom: 30 }}>
                    LEGGE - CIDADANIA ITALIANA
                    <br></br>
                    <Container style={{ fontSize: '14px', fontWeight: 1 }} >
                        Brasil - USA - Itália
                    </Container>
                    <Container>
                        <a href="https://api.whatsapp.com/send?phone=4078073529" target='_blank'>
                            <Image src={WhatsAppBotao} style={{ marginTop: 10, width: 200 }}></Image>
                        </a>
                    </Container>
                </Col>
                <Col xs={12} md={4} className="Logo" style={{ color: 'white', fontSize: '20px', fontWeight: 500, marginBottom: 30 }}>
                    Mapa do Site
                    <br></br>
                    <Container style={{ fontSize: '17px', fontWeight: 1 }} >
                        <a className="rodape" href="/#home"> Home </a>
                        <br></br>
                        <a className="rodape" href="/#nossoServicos"> Serviços </a>
                        <br></br>
                        <a className="rodape" href="/#experiencia"> Experiência </a>

                        <br></br>
                        <a className="rodape" href="/#sobreNos"> Sobre Nós </a>

                    </Container>

                </Col>
                <Col xs={12} md={4} className="Logo" style={{ color: 'white', fontSize: '20px', fontWeight: 500, marginBottom: 30 }}>
                    Midias Sociais
                    <br></br>
                    <Container style={{ fontSize: '17px', fontWeight: 1 }} >
                        <FontAwesomeIcon icon={faFacebook} color={"white"} style={{ fontSize: '20px', marginRight: 5 }} />
                        Facebook
                        <br></br>
                        <FontAwesomeIcon icon={faInstagram} color={"white"} style={{ fontSize: '20px', marginRight: 5 }} />
                        Instagram
                        <br></br>
                    </Container>

                </Col>
            </Row>
        </Container>
    )

}

export default Rodape;
