import React from 'react';
import {
    Container,
    Image,
    Card,
    Row,
    Col,
    Button
} from 'react-bootstrap';

import './../Components.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'

function CartaoServico() {

    return (

        <Container id="comofunciona" fluid style={{ margin: 0, padding: 20, backgroundColor: 'white', marginBottom: 10 }}>
            <Row>
                <Col xs={12} lg={12} md={12} className="titleSessionProduct" style={{ margin: 'auto' }} >
                    <h5>Nosso produtos</h5>
                </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
                <Col xs={12} lg={5} md={4} className="productsIcon" style={{ color: '#024325', marginBottom: 20 }} >
                    <FontAwesomeIcon icon={faCreditCard} size="3x" color={"#2f4a74"} />
                </Col>
                <Col xs={12} lg={5} md={8} className="productsContent" style={{ margin: 'auto', marginLeft: 10, marginBottom: 20, paddingBottom: 20 }}>
                    <strong>Quer comprar algo no EUA, ou mesmo pagar alguma conta? Você pode parcelar. Com o seu cartão de crédito do Brasil, você pode parcelar seus valores e receber no EUA.</strong>
                    <br />
                    <br />
                    <Button variant="success" type="submit">
                        Simular
                    </Button>

                </Col>
            </Row>

        </Container>
    )

}

export default CartaoServico;
