/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomerProfile = /* GraphQL */ `
  query GetCustomerProfile($id: ID!) {
    getCustomerProfile(id: $id) {
      id
      email
      senha
      nome
      sobrenome
      cpf
      datanascimento
      endereco
      complemento
      numero
      bairro
      cidade
      estado
      telefone
      celular
      cep
      b2b
      routingnumber
      accountnumber
      nomeloja
    }
  }
`;
export const listCustomerProfiles = /* GraphQL */ `
  query ListCustomerProfiles(
    $filter: ModelCustomerProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        senha
        nome
        sobrenome
        cpf
        datanascimento
        endereco
        complemento
        numero
        bairro
        cidade
        estado
        telefone
        celular
        cep
        b2b
        routingnumber
        accountnumber
        nomeloja
      }
      nextToken
    }
  }
`;
export const getCurrencyRate = /* GraphQL */ `
  query GetCurrencyRate($id: ID!) {
    getCurrencyRate(id: $id) {
      id
      datacotacao
      moedaorigemsimbolo
      moedaorigem
      moedadestinosimbolo
      moedadestino
      valor
    }
  }
`;
export const listCurrencyRates = /* GraphQL */ `
  query ListCurrencyRates(
    $filter: ModelCurrencyRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrencyRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        datacotacao
        moedaorigemsimbolo
        moedaorigem
        moedadestinosimbolo
        moedadestino
        valor
      }
      nextToken
    }
  }
`;
export const getPaymentRequest = /* GraphQL */ `
  query GetPaymentRequest($id: ID!) {
    getPaymentRequest(id: $id) {
      id
      emailcliente
      moedaorigemsimbolo
      moedaorigem
      moedadestinosimbolo
      moedadestino
      valorrequerido
      moedarequerida
      valorcotacao
      descricao
      personalshopperb2b {
        id
        nome
        sobrenome
        nomeloja
      }
      status
      datageracao
    }
  }
`;
export const listPaymentRequests = /* GraphQL */ `
  query ListPaymentRequests(
    $filter: ModelPaymentRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emailcliente
        moedaorigemsimbolo
        moedaorigem
        moedadestinosimbolo
        moedadestino
        valorrequerido
        moedarequerida
        valorcotacao
        descricao
        personalshopperb2b {
          id
          nome
          sobrenome
          nomeloja
        }
        status
        datageracao
      }
      nextToken
    }
  }
`;
